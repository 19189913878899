import { Button, useTheme } from "@equiem/react-end-user-ui";
import { useContext } from "react";
import { ChatContext } from "./ChatContext";
import { RiCloseLine, RiSubtractLine } from "@equiem/react-admin-ui/icons";
import { BotAvatar } from "./BotAvatar";

export const ChatWindowHeader = () => {
  const { colors, spacers } = useTheme();
  const { setOpen } = useContext(ChatContext);

  return (
    <>
      <div className="container">
        <div className="d-flex align-items-center">
          <BotAvatar invert={true} />
          <h4>AI Concierge</h4>
        </div>
        <div>
          <Button
            round
            size="sm"
            onClick={() => {
              void setOpen(false);
            }}
          >
            <RiSubtractLine color={colors.primaryContrast} size={20} />
          </Button>
          <Button
            round
            size="sm"
            className="ml-1 chatClose"
            onClick={() => {
              void setOpen(false, true);
            }}
          >
            <RiCloseLine color={colors.primaryContrast} size={20} />
          </Button>
        </div>
      </div>
      <style jsx>{`
        .container {
          background: ${colors.primary};
          color: ${colors.white};
          padding: ${spacers.s4};
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        h4 {
          padding: 0;
          margin: 0 0 0 ${spacers.s3};
          cursor: default;
          font-size: ${spacers.s5};
          line-height: ${spacers.s5};
          color: ${colors.primaryContrast};
          font-weight: 600;
        }
      `}</style>
    </>
  );
};
