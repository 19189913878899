import { Array, Boolean, Literal, Null, Number, Partial, Record, Static, String, Union } from "runtypes";

export const ModChoice = Record({
  modUuid: String,
  modChoiceUuids: Array(String),
}).And(Partial({
  value: String,
}));
export type ModChoice = Static<typeof ModChoice>;

export const PlainProduct = Record({
  modChoices: Array(ModChoice),
});
export type PlainProduct = Static<typeof PlainProduct>;

export const Subscription = Record({
  modChoices: Array(ModChoice),
});
export type Subscription = Static<typeof Subscription>;

export const Session = Record({
  sessionTemplateUuid: String,
  start: Number,
  end: Number,
  attendees: Number,
});
export type Session = Static<typeof Session>;

export const Booking = Record({
  session: Session,
  modChoices: Array(ModChoice),
});
export type Booking = Static<typeof Booking>;

const BaseItem = Record({
  uuid: String,
  vendor: Record({
    uuid: String,
    name: String,
  }),
});
type BaseItem = Static<typeof BaseItem>;

export const ItemPlain = BaseItem.And(Record({
  type: Literal("PLAIN"),
  selected: PlainProduct,
}));
export type ItemPlain = Static<typeof ItemPlain>;

export const ItemSubscription = BaseItem.And(Record({
  type: Literal("SUBSCRIPTION"),
  selected: Subscription,
  billingCycle: Union(
    Literal("MONTHLY"),
    Literal("WEEKLY"),
    Literal("HALF_YEARLY"),
    Literal("YEARLY"),
  ),
}));
export type ItemSubscription = Static<typeof ItemSubscription>;

export const ItemBooking = BaseItem.And(Record({
  type: Literal("BOOKING"),
  selected: Booking,
  timezone: String,
}));
export type ItemBooking = Static<typeof ItemBooking>;

export const ItemCommon = Record({
  id: String,
  quantity: Number,
  // Subtotal will be updated by the cart item component.
}).And(Partial({
  subtotal: Number,
}));
export type ItemCommon = Static<typeof ItemCommon>;

export const ItemInput = ItemBooking.Or(ItemPlain).Or(ItemSubscription);
export type ItemInput = Static<typeof ItemInput>;
export const Item = ItemInput.And(ItemCommon);
export type Item = Static<typeof Item>;

export const CartReducerState = Record({
  items: Array(Item),
  updated: Union(Number, Null),
  itemCount: Number,
  total: Union(Number, Null),
  showCartModal: Boolean,
  showCartBtn: Boolean,
  discountCode: Union(String, Null),
});
export type CartReducerState = Static<typeof CartReducerState>;
