import { useContext } from "react";
import { Button } from "@equiem/react-end-user-ui";
import { ChatContext } from "./ChatContext";
import { Boop } from "./Boop";
import { RiRobot3Line } from "@equiem/react-admin-ui/icons";
import { Site } from "@equiem/web-ng-lib";

export const ChatBotButton = () => {
  const { open, setOpen } = useContext(ChatContext);
  const site = useContext(Site);

  return (
    <>
      <div className="buttonWindow">
        <Boop rotation={30} scale={1.1}>
          <Button
            round
            className="chatBotButton"
            style={{
              width: 56,
              height: 56,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: `solid 2px ${site.primaryContrastColour}`,
            }}
            onClick={() => {
              void setOpen(!open, open);
            }}
          >
            <RiRobot3Line size={24} />
          </Button>
        </Boop>
      </div>
      <style jsx>{`
        .buttonWindow {
          display: flex;
          justify-content: flex-end;
          margin: 20px;
          position: fixed;
          bottom: 0;
          right: 0;
          z-index: 2000000002 !important; /* needs to be larger than the header. */
        }
      `}</style>
    </>
  );
};
