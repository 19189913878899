import type { FC } from "react";
import { useContext, useEffect } from "react";
import { ChatWindow } from "./ChatWindow";
import { ChatContext } from "./ChatContext";
import { animated, useSpring } from "@react-spring/web";
import { ChatBotButton } from "./ChatBotButton";
import { ChatCallout } from "./ChatCallout";

interface Props {
  fullPage: boolean;
}

export const ChatBotInner: FC<Props> = ({ fullPage }) => {
  const { open, showCallout } = useContext(ChatContext);
  const [springs, api] = useSpring(() => ({
    from: { opacity: 0 },
  }));

  useEffect(() => {
    let from = { opacity: 1, outerHeight: 400, width: 400, height: 500 };
    let to = { opacity: 0, outerHeight: 0, width: 0, height: 0 };
    let duration = 0;

    if (open) {
      from = { opacity: 0, outerHeight: 0, width: 0, height: 0 };
      to = { opacity: 1, outerHeight: 400, width: 400, height: 500 };
      duration = 200;
    }

    (async () => {
      await Promise.all(
        api.start({
          from,
          to,
          config: {
            duration,
            tension: 300,
            friction: 10,
          },
        }),
      ).catch(console.error);
    })().catch(console.error);
  }, [open, api]);

  return (
    <>
      <animated.div
        style={{
          overflow: "hidden",
          position: "fixed",
          bottom: 0,
          right: 0,
          marginBottom: fullPage ? 0 : 20,
          marginRight: fullPage ? 0 : 20,
          zIndex: 2000000002,
          boxShadow: "1px -1px 13px 0px rgba(0, 0, 0, 0.1)",
          width: fullPage ? "100%" : 400,
          height: fullPage ? "100%" : undefined,
          ...(fullPage ? {} : springs),
        }}
        className={"chatWindow"}
      >
        <ChatWindow fullPage={fullPage} />
      </animated.div>
      {!fullPage && showCallout && <ChatCallout />}
      {!fullPage && !open && <ChatBotButton />}
    </>
  );
};
