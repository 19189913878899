import { useTranslation } from "@equiem/web-ng-lib";
import { Button, Site, Theme } from "@equiem/web-ng-lib";
import Link from "next/link";
import { useRouter } from "next/router";
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAlert } from "react-alert";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Cookie } from "../contexts/CookieContext";
import { useLogoBackground } from "../hooks/useLogoBackground";
import { CookieSettings } from "../lib/CookieSettings";
import { provideRef } from "../lib/provideRef";
import { SlideToggle } from "./form/SlideToggle";

interface Props {
  className?: string;
}

export interface Ref {
  open: () => void;
  close: () => void;
}

export const CookieSettingsDrawer = forwardRef<Ref, Props>(
  ({ className = "" }, ref) => {
    const { t } = useTranslation();

    if (typeof localStorage === "undefined") {
      return null;
    }

    const router = useRouter();
    const alert = useAlert();
    const {
      consentRequired,
      settings: savedSettings,
      update: updateSettings,
    } = useContext(Cookie);
    const site = useContext(Site);
    const theme = useContext(Theme);
    const [show, setShow] = useState(savedSettings == null && consentRequired);
    const [showSettings, setShowSettings] = useState(false);
    const { bgColor } = useLogoBackground();

    const { handleSubmit, register, watch, reset } = useForm<CookieSettings>({
      defaultValues: savedSettings ?? {
        performance: false,
        functionality: false,
      },
    });
    const { performance, functionality } = watch();

    const open = useCallback(() => {
      setShow(true);
    }, []);

    const close = useCallback(() => {
      setShowSettings(false);
      setTimeout(() => {
        setShow(false);
      }, 500);
    }, []);

    provideRef(ref, { open, close });

    useEffect(() => {
      if (show) {
        setShowSettings(true);
      }
    }, [show]);

    const onAccept = (settings: CookieSettings) => {
      updateSettings(settings);
      reset(settings);
      close();
      alert.success(t("main.cookiesAcceptedNotification"));
    };

    const onDecline = () => {
      updateSettings(null);
      router.push("/api/logout").catch((e) => {
        console.error(e);
      });
    };

    if (!show) {
      return null;
    }

    return (
      <Modal
        show={true}
        dialogClassName={`drawer ${className}`}
        contentClassName={`cookie-settings text-site-contrast bg-site ${showSettings ? "show" : ""}`}
        centered={false}
        animation={false}
        backdrop="static"
        keyboard={false}
        scrollable={false}
      >
        <div className="logo d-md-none">
          <Container className="p-4">
            <Row>
              <Col md={8} className="text-left">
                <h3 className="logo-container m-0">
                  {site.logo != null ? <img src={site.logo} /> : site.name}
                </h3>
              </Col>
            </Row>
          </Container>
        </div>
        <Form onSubmit={handleSubmit(onAccept)}>
          <Container className="px-4 pt-4">
            <Row>
              <Col md={8} className="text-left">
                <h4>{t("main.ourUseOfCookiesTitle")}</h4>
                <p>{t("main.ourUseOfCookiesDescription")}</p>
                <p>
                  <Link href="/cookies" passHref>
                    <a>
                      <u>{t("main.readMoreAboutCookiePolicy")}</u>
                    </a>
                  </Link>
                </p>
              </Col>
            </Row>
            <Row className="my-3 p-0">
              <Col md={4} className="setting pr-md-4 pr-lg-5 pb-3 pb-md-0">
                <h5 className="my-3 h6">{t("main.necessaryCookiesTitle")}</h5>
                <p>{t("main.necessaryCookiesDescription")}</p>
                <div className="control disabled">
                  <label htmlFor="necessary">{t("main.alwaysEnabled")}</label>
                  <SlideToggle
                    id="necessary"
                    color="contrast"
                    disabled
                    checked={true}
                  />
                </div>
              </Col>
              <Col md={4} className="setting px-md-4 px-lg-5 pb-3 pb-md-0">
                <h5 className="my-3 h6">{t("main.performanceCookiesTitle")}</h5>
                <p>{t("main.performanceCookiesDescription")}</p>
                <div className="control">
                  <label htmlFor="performance">
                    {performance
                      ? t("main.performanceCookiesActive")
                      : t("main.performanceCookiesInactive")}
                  </label>
                  <SlideToggle
                    {...register("performance")}
                    name="performance"
                    id="performance"
                    color="contrast"
                  />
                </div>
              </Col>
              <Col md={4} className="setting pl-md-4 pl-lg-5 pb-3 pb-md-0">
                <h5 className="my-3 h6">
                  {t("main.functionalityCookiesTitle")}
                </h5>
                <p>{t("main.functionalityCookiesDescription")}</p>
                <div className="control">
                  <label htmlFor="functionality">
                    {functionality
                      ? t("main.functionalityCookiesActive")
                      : t("main.functionalityCookiesInactive")}
                  </label>
                  <SlideToggle
                    {...register("functionality")}
                    name="functionality"
                    id="functionality"
                    color="contrast"
                  />
                </div>
              </Col>
            </Row>
          </Container>
          <Container fluid="md" className="py-4 px-md-4 buttons bg-site">
            <Row className="mt-3">
              <Col className="text-md-left">
                {/* Do not remove accept-terms-button class name. It's used in external automated tests */}
                <Button
                  className="accept-terms-button"
                  variant="solid"
                  contrast
                >
                  {t("main.acceptAndContinue")}
                </Button>
                <Button
                  variant="link"
                  contrast
                  type="button"
                  className="decline pr-0"
                  onClick={onDecline}
                >
                  {t("main.declineAndSignOut")}
                </Button>
              </Col>
            </Row>
          </Container>
        </Form>

        <style jsx global>{`
          .drawer {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            color: #fff;
            position: fixed !important;
            bottom: 0 !important;
            left: 0 !important;
            width: 100% !important;
            max-width: none !important;
            margin: 0 !important;
          }
          .drawer .setting {
            text-align: left;
            display: flex;
            flex-direction: column;
          }
          .cookie-settings {
            transition: transform 500ms;
            box-shadow: -15px 0 20px rgba(0, 0, 0, 0.5);
            overflow: auto;
            max-height: 100vh;
            transform: translate(0, 100%);
          }
          .cookie-settings.show {
            transform: translate(0, 0);
          }
          .cookie-settings .buttons {
            position: sticky;
            bottom: 0;
          }
          .cookie-settings .decline {
            font-size: 1rem;
          }
          @media (max-width: ${theme.breakpoints.md - 1}px) {
            .cookie-settings .buttons {
              box-shadow: -15px 0 20px rgba(0, 0, 0, 0.5);
            }
          }
        `}</style>
        <style jsx>{`
          .logo {
            background: ${bgColor};
          }
          .control {
            margin-top: auto;
            padding-top: 0.5rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .control.disabled label {
            opacity: 0.5;
          }
          .control label {
            cursor: pointer;
            margin: 0;
            flex-grow: 1;
            padding-right: 1rem;
            letter-spacing: -0.28px;
          }
        `}</style>
      </Modal>
    );
  },
);
