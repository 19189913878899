import { RiThumbDownLine, RiThumbUpLine } from "@equiem/react-admin-ui/icons";
import { Button, useTheme } from "@equiem/react-end-user-ui";
import type { FC } from "react";
import { useState } from "react";
import { useSendAiChatFeedbackMutation } from "../generated/ai-client";

interface Props {
  question: string;
  response: string;
}

type Feedback = "positive" | "negative";

export const ChatFeedbackMessage: FC<Props> = ({ question, response }) => {
  const { spacers, colors } = useTheme();
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [feedbackProvided, setFeedbackProvided] = useState<Feedback | null>(null);
  const [mutation] = useSendAiChatFeedbackMutation();

  const handleFeedback = async (feedback: Feedback) => {
    if (submitted) {
      return;
    }

    // Run a callback in 2 seconds to simulate a network request
    setSubmitting(true);
    setFeedbackProvided(feedback);

    await mutation({
      variables: {
        isPositive: feedback === "positive",
        question: question,
        response: response,
      },
    });

    setSubmitted(true);
  };

  return (
    <>
      {submitted ? (
        <p>Thank you for your feedback</p>
      ) : (
        <>
          <div>
            <Button
              className="positive-feedback mr-1"
              disabled={submitting}
              variant={feedbackProvided === "positive" ? "primary" : "secondary"}
              onClick={() => {
                void handleFeedback("positive");
              }}
              size="sm"
            >
              <RiThumbUpLine size={24} />
            </Button>
            <Button
              className="negative-feedback"
              disabled={submitting}
              variant={feedbackProvided === "negative" ? "primary" : "secondary"}
              onClick={() => {
                void handleFeedback("negative");
              }}
              size="sm"
            >
              <RiThumbDownLine size={24} />
            </Button>
          </div>
          <p>Your feedback will help me improve</p>
        </>
      )}
      <style jsx>{`
        div {
          margin-top: ${spacers.s2};
        }
        p {
          margin-top: ${spacers.s2};
          font-size: ${spacers.s4};
          color: ${colors.muted0};
        }
      `}</style>
    </>
  );
};
