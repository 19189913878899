import { stringNotEmpty } from "./stringNotEmpty";
import DOMPurify from "dompurify";
import React from "react";

interface Props {
  className?: string;
  html?: string | null;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

// Allow `target="_blank"` but require `rel="noopener"` and/or `rel="noreferrer"`.
// See:
//   - https://developer.chrome.com/docs/lighthouse/best-practices/external-anchors-use-rel-noopener/
//   - https://github.com/cure53/DOMPurify/issues/317
//
// This is conditional because dompurify doesn't work serverside.
let sanitiseOptions: DOMPurify.Config = {};
if (typeof DOMPurify.addHook === "function") {
  const isAnchorTag = (node: Element): node is HTMLAnchorElement =>
    node.tagName === "A";

  DOMPurify.addHook("afterSanitizeAttributes", (node) => {
    const target = node.getAttribute("target");
    if (!isAnchorTag(node) || target !== "_blank") {
      node.removeAttribute("target");
      node.removeAttribute("rel");
      return;
    }
    const rel = node.getAttribute("rel");
    if (!/\b(noopener|noreferrer)\b/.test(rel ?? "")) {
      node.setAttribute("rel", "noopener noreferrer");
    }
  });

  sanitiseOptions = {
    ADD_ATTR: ["target", "rel"],
  };
}

export const EscapedHtmlDiv: React.FC<Props> = ({
  className = "",
  style = {},
  html,
  children,
}) => {
  return (
    <>
      {stringNotEmpty(html) ? (
        <div
          className={className}
          style={style}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(html, {
              ...sanitiseOptions,
              RETURN_DOM_FRAGMENT: false,
              RETURN_DOM: false,
            }),
          }}
        >
          {children}
        </div>
      ) : null}
      <style jsx>{`
        div :global(img) {
          max-width: 100%;
        }
      `}</style>
    </>
  );
};
