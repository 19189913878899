import { formatters, useTranslation } from "@equiem/web-ng-lib";
import React from "react";
import { SubscriptionBillingCycle } from "../generated/gateway-client";

interface Props {
  price: number;
  currencyCode: string;
  freeTxt: string;
  billingCycle?: SubscriptionBillingCycle;
}

export const PriceFormatForCurrency: React.FC<Props> = ({
  price,
  currencyCode,
  freeTxt,
  billingCycle,
}) => {
  const { t, i18n } = useTranslation();

  if (price === 0) {
    return <>{freeTxt}</>;
  }

  const cycleSuffix =
    billingCycle == null
      ? null
      : {
          [SubscriptionBillingCycle.Monthly]: `/${t("main.month")}`,
          [SubscriptionBillingCycle.Weekly]: `/${t("main.week")}`,
          [SubscriptionBillingCycle.HalfYearly]: `/${t("main.halfYear")}`,
          [SubscriptionBillingCycle.Yearly]: `/${t("main.year")}`,
        }[billingCycle];

  return (
    <>
      {formatters.currency(price / 100, i18n.language, {
        currency: currencyCode,
      })}
      {cycleSuffix}
    </>
  );
};
