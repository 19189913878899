import { useContext, useEffect, useState } from "react";
import { ASSISTANT, ChatContext } from "./ChatContext";
import { ChatMessage, RichText } from "./ChatMessage";
import { ChatWindowSuggestions } from "./ChatWindowSuggestions";
import { useTheme } from "@equiem/react-end-user-ui";
import { ChatLoadingContent } from "./ChatLoadingContent";
import { ChatPrivacy } from "./ChatPrivacy";

export const ChatWindowBody = () => {
  const { thread, loading } = useContext(ChatContext);
  const [showLoading, setShowLoading] = useState(false);
  const { spacers } = useTheme();

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (loading) {
      timeout = setTimeout(() => {
        setShowLoading(true);
      }, 700);
    } else {
      setShowLoading(false);
    }

    return () => clearTimeout(timeout);
  }, [loading]);

  return (
    <>
      <div className="windowBody">
        <div className="windowInner">
          <ChatPrivacy />
          {thread.map(({ role, date, message, showFeedback }, index) =>
            role === ASSISTANT ? (
              <ChatMessage
                animate={false}
                key={index}
                date={date}
                role={role}
                value={<RichText markdown={message} />}
                rawMessage={message}
                showFeedback={showFeedback}
                previousMessage={thread[index - 1]?.message}
              />
            ) : (
              <ChatMessage
                key={index}
                date={date}
                role={role}
                value={message}
                showFeedback={showFeedback}
                rawMessage={message}
              />
            ),
          )}
          {showLoading && (
            <ChatMessage
              isLoader={true}
              role={ASSISTANT}
              date={new Date()}
              value={<ChatLoadingContent />}
              rawMessage={""}
              showFeedback={false}
            />
          )}
        </div>
      </div>
      <ChatWindowSuggestions />
      <style jsx>{`
        .windowBody {
          overflow-y: auto;
          display: flex;
          flex-direction: column-reverse;
          justify-content: space-between;
          padding: ${spacers.s3} ${spacers.s4};
          flex: 2;
        }
        .windowInner {
          flex: 1;
        }
      `}</style>
    </>
  );
};
