import { Alert, useTheme } from "@equiem/react-end-user-ui";
import { useContext, useEffect } from "react";
import { ChatContext } from "./ChatContext";
import { animated, useSpring } from "@react-spring/web";

export const ChatPrivacy = () => {
  const { showPrivacy, setShowPrivacy } = useContext(ChatContext);
  const { spacers, colors } = useTheme();

  const [style, api] = useSpring(() => ({
    from: { opacity: 1, scale: 1, height: "3.5rem" },
  }));

  useEffect(() => {
    if (!showPrivacy) {
      const doIt = async () => {
        await Promise.all(
          api.start({
            to: {
              opacity: 0,
              scale: 0,
              height: "0px",
            },
            config: {
              duration: 200,
              tension: 300,
              friction: 10,
            },
          }),
        );
      };

      void doIt();
    }
  }, [showPrivacy, api]);

  return (
    <>
      <animated.div style={style}>
        <Alert
          onClose={() => setShowPrivacy(false)}
          message={
            <p style={{ fontSize: "14px", paddingTop: spacers.s4 }}>
              By using this chatbot, you consent to our{" "}
              <a href="/legal-chatbot" id="privacyLink" target="_blank">
                Terms & Conditions
              </a>
              .
            </p>
          }
          variant="neutral"
        />
      </animated.div>
      <style jsx>{`
        #privacyLink {
          color: ${colors.light};
          text-decoration: underline;
        }
      `}</style>
    </>
  );
};
