import { useTranslation } from "@equiem/web-ng-lib";
import { Button } from "@equiem/web-ng-lib";
import Link from "next/link";
import React, { useContext, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { Cart } from "../../contexts/CartProvider";
import { SubscriptionBillingCycle } from "../../generated/gateway-client";
import { PriceFormat } from "../PriceFormat";
import { UserCartItems } from "./UserCartItems";

export const UserCart: React.FC = () => {
  const { t } = useTranslation();

  const { total, items, productType, showCartModal, hide } = useContext(Cart);
  const type = useMemo(productType, [productType]);

  return (
    <>
      <Modal show={showCartModal} size="lg" onHide={hide} centered>
        <Modal.Header closeButton>
          <Modal.Title className="flex-grow-1">
            <div style={{ width: "30px" }}></div>
            <div className="text-center h5 my-1">{t("main.yourOrder")}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserCartItems>
            {
              total != null ? (
                <div className="pt-3">
                  <Link href="/store/checkout" passHref>
                    <Button variant="solid" size="lg" className="w-100">
                      {t("main.goToCheckout")} - <PriceFormat price={total} billingCycle={items[0].type !== "SUBSCRIPTION" ? undefined : {
                        WEEKLY: SubscriptionBillingCycle.Weekly,
                        MONTHLY: SubscriptionBillingCycle.Monthly,
                        HALF_YEARLY: SubscriptionBillingCycle.HalfYearly,
                        YEARLY: SubscriptionBillingCycle.Yearly,
                      }[items[0].billingCycle]} freeTxt={t("main.free")} />
                    </Button>
                  </Link>
                  { type === "SUBSCRIPTION" ? null : (
                    <Link href={`/store/vendor/${items[0].vendor.uuid}`} passHref>
                      <Button variant="outline" size="lg" className="mt-3 w-100" onClick={hide}>
                        {t("main.continueShopping")}
                      </Button>
                    </Link>
                  )}
                </div>
              ) : null
            }
          </UserCartItems>
        </Modal.Body>
      </Modal>
      <style jsx>{`
        .cart-item-mods {
          font-size: 14px;
        }
      `}</style>
    </>
  );
}
