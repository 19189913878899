import { Card, useTheme } from "@equiem/react-end-user-ui";
import { ChatContext } from "./ChatContext";
import { useContext } from "react";
import { RiCloseLine } from "@equiem/react-admin-ui/icons";

export const ChatCallout = () => {
  const { helloMessage, closeCallout, setOpen } = useContext(ChatContext);
  const { colors, spacers } = useTheme();

  return (
    <>
      <Card.Card
        className="callout"
        style={{
          overflow: "hidden",
          position: "fixed",
          bottom: 0,
          right: 0,
          marginBottom: 90,
          marginRight: 20,
          zIndex: 2000000002,
          boxShadow: "1px -1px 13px 0px rgba(0, 0, 0, 0.1)",
          width: 400,
          backgroundColor: colors.grayscale[3],
        }}
      >
        <Card.CardBody>
          <div className="inner">
            <div
              className="content"
              onClick={() => {
                void setOpen(true);
              }}
            >
              {helloMessage}
            </div>
            <div className="close">
              <span className="closeBtn" onClick={closeCallout}>
                <RiCloseLine />
              </span>
            </div>
          </div>
        </Card.CardBody>
      </Card.Card>
      <style jsx>{`
        .inner {
          display: flex;
          justify-content: space-between;
          align-items: top;
        }
        .close {
          padding: ${spacers.s0} ${spacers.s0} ${spacers.s0} ${spacers.s5};
        }
        .closeBtn,
        .content {
          cursor: pointer;
        }
      `}</style>
    </>
  );
};
