import { Button, useTheme } from "@equiem/react-end-user-ui";
import { ChatContext } from "./ChatContext";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { RiSendPlaneLine } from "@equiem/react-admin-ui/icons";

export const ChatWindowInput = () => {
  const { sendMessage, loading, open } = useContext(ChatContext);
  const { spacers, colors } = useTheme();
  const [input, setInput] = useState<string>("");
  const ref = useRef<HTMLDivElement>(null);

  const isEmpty = useMemo(() => {
    return input.trim() === "";
  }, [input]);

  const onSubmit = async () => {
    if (isEmpty) {
      return;
    }
    setInput("");
    if (ref.current != null) {
      ref.current.innerHTML = "";
    }

    await sendMessage(input);
    ref.current?.focus();
  };

  useEffect(() => {
    if (open) {
      ref.current?.focus();
    }
  }, [open]);

  const handleInput = (e: React.FormEvent<HTMLDivElement>) => {
    if (loading) {
      e.preventDefault();
      return;
    }
    if (e.currentTarget.textContent != null) {
      setInput(e.currentTarget.textContent);
    } else if (ref.current != null) {
      ref.current.innerHTML = "";
    }
  };

  return (
    <>
      <div className="cont">
        <div
          ref={ref}
          contentEditable={!loading}
          data-placeholder={loading ? "Awaiting answer..." : "How can I help?"}
          onInput={handleInput}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              void onSubmit();
            }
          }}
        />
        <span>
          <Button
            round
            disabled={loading || isEmpty}
            onClick={() => {
              void onSubmit();
            }}
            size="sm"
          >
            <RiSendPlaneLine />
          </Button>
        </span>
      </div>
      <style jsx>{`
        div[contenteditable] {
          border: none;
          outline: none;
          max-height: 100px;
          overflow: auto;
          width: 100%;
          margin-right: ${spacers.s4};
          padding: ${spacers.s2};
        }
        .cont {
          padding: ${spacers.s4};
          display: flex;
          justify-content: space-between;
          align-items: end;
          border-top: solid 1px ${colors.border};
        }
        [data-placeholder]:empty:before {
          content: attr(data-placeholder);
          color: ${colors.muted1};
          font-style: italic;
        }
      `}</style>
    </>
  );
};
