/* eslint-disable */
import type { FC } from "react";

import {
  type UseTranslationOptions,
  type TFunctionGeneric,
  useTranslationGeneric,
  type WithTranslationProps as WithTranslationPropsGeneric,
  withTranslationGeneric,
  type TransProps as TransPropsGeneric,
  makeTransComponent,
  standaloneTestT as standaloneTestTInternal,
  type StandaloneTestTOptions,
  getCustomStandaloneTestT as getCustomStandaloneTestTInternal,
  type FrontendLanguages as Languages,
} from "@equiem/localisation"

import enApplication from "./../locales/en/web-ng.json";
import enServer from "./../locales/en/server-messages.json";

/** @public */
export type ServerMessages = {
  "activity-api.errors.commentRequired": undefined;
  "activity-api.errors.createChangelogFailed": undefined;
  "activity-api.errors.createCommentFailed": undefined;
  "activity-api.errors.noAccessToDoThisOperation": undefined;
  "activity-api.errors.userNotFound": undefined;
  "admin-api.errors.apartment.alreadyExists": { name: string | number };
  "admin-api.errors.barrierAccessControl.alreadyExists": undefined;
  "admin-api.errors.barrierAccessControl.notFound": undefined;
  "admin-api.errors.barrierAccessControl.notFoundAccess": undefined;
  "admin-api.errors.building.buildingNameCannotBeEmpty": undefined;
  "admin-api.errors.building.buildingNameExistsWithinTheSiteAlready": undefined;
  "admin-api.errors.building.buildingsMustBeInsideTheSameDestination": undefined;
  "admin-api.errors.building.buildingsNotFound": undefined;
  "admin-api.errors.building.destinationUuidCannotBeEmpty": undefined;
  "admin-api.errors.building.failedToDuplicateBuilding": undefined;
  "admin-api.errors.building.failedToUpdateBuildingIntegrations": undefined;
  "admin-api.errors.building.failedToUpdateBuildings": undefined;
  "admin-api.errors.building.taxRateLabelCannotBeEmpty": undefined;
  "admin-api.errors.building.taxRateLabelMustBeUnique": undefined;
  "admin-api.errors.building.taxRatesCannotBeDeleted": undefined;
  "admin-api.errors.building.taxRatesCannotBeNegative": undefined;
  "admin-api.errors.buildingLevel.buildingNotFound": undefined;
  "admin-api.errors.buildingLevel.failedToCreate": undefined;
  "admin-api.errors.buildingLevel.failedToDelete": undefined;
  "admin-api.errors.buildingLevel.failedToDuplicate": undefined;
  "admin-api.errors.buildingLevel.failedToUpdate": undefined;
  "admin-api.errors.buildingLevel.failedToUpdateOrder": undefined;
  "admin-api.errors.buildingLevel.mustBeInsideTheSameBuilding": undefined;
  "admin-api.errors.buildingLevel.nameCannotBeEmpty": undefined;
  "admin-api.errors.buildingLevel.nameExistsOnBuilding": { name: string | number, buildingName: string | number };
  "admin-api.errors.buildingLevel.notFound": undefined;
  "admin-api.errors.buildingLevel.notFoundMultiple": undefined;
  "admin-api.errors.common.accessDenied": undefined;
  "admin-api.errors.common.regionNotProvided": undefined;
  "admin-api.errors.common.unknownError": undefined;
  "admin-api.errors.common.userNoPermissionDestination": undefined;
  "admin-api.errors.common.userNoPermissionDestinationOrEquiemOne": undefined;
  "admin-api.errors.common.userNotAuthenticated": undefined;
  "admin-api.errors.common.userNotAuthorisedActingAs": { uuid: string | number, userUuid: string | number };
  "admin-api.errors.common.userNotAuthorisedAddLevels": undefined;
  "admin-api.errors.common.userNotAuthorisedSite": undefined;
  "admin-api.errors.company.notFound": undefined;
  "admin-api.errors.flex.membershipLimitReached": undefined;
  "admin-api.errors.flex.moveOutDateBeforeMoveInDate": undefined;
  "admin-api.errors.flex.noFlexOperatorFound": undefined;
  "admin-api.errors.flex.noFlexTenantsFound": undefined;
  "admin-api.errors.invites.missingCompany": { emails: string | number, count: number };
  "admin-api.errors.invites.unknownRole": undefined;
  "admin-api.errors.space.buildingLevelNotFound": undefined;
  "admin-api.errors.space.buildingLevelNotFoundMultiple": undefined;
  "admin-api.errors.space.failedToBulkCreate": undefined;
  "admin-api.errors.space.failedToCreate": undefined;
  "admin-api.errors.space.failedToDelete": undefined;
  "admin-api.errors.space.failedToDuplicate": undefined;
  "admin-api.errors.space.failedToFindSite": undefined;
  "admin-api.errors.space.failedToSaveAudience": undefined;
  "admin-api.errors.space.failedToUpdate": undefined;
  "admin-api.errors.space.failedToUpdateOrder": undefined;
  "admin-api.errors.space.mustBeInsideTheSameLevel": undefined;
  "admin-api.errors.space.nameCannotBeEmpty": undefined;
  "admin-api.errors.space.nameExistsOnLevel": { name: string | number, levelName: string | number };
  "admin-api.errors.space.notFound": undefined;
  "admin-api.errors.space.notFoundMultiple": undefined;
  "admin-api.errors.space.siteAudienceCannotBeEmpty": undefined;
  "admin-api.errors.statsProvider.urlNotProvided": undefined;
  "admin-api.errors.whitelabel.appInDraft": undefined;
  "admin-api.errors.whitelabel.appRetired": undefined;
  "admin-api.errors.whitelabel.appleApiKeyRequired": undefined;
  "admin-api.errors.whitelabel.bundleIdRequired": undefined;
  "admin-api.errors.whitelabel.failedToCheckAccount": undefined;
  "admin-api.errors.whitelabel.missingAppleApiKey": undefined;
  "admin-api.errors.whitelabel.mustProvideClientUUIDAndName": undefined;
  "bookings-api.bookings.errors.addOnNotFound": undefined;
  "bookings-api.bookings.errors.additionalChargeUnsuccessful": { currency: string, price: number, reference: string | number };
  "bookings-api.bookings.errors.adjustmentNotFound": undefined;
  "bookings-api.bookings.errors.allBookingsMustBeForSameResource": undefined;
  "bookings-api.bookings.errors.allBookingsMustBeForSameUser": undefined;
  "bookings-api.bookings.errors.allBookingsMustUseSameCard": undefined;
  "bookings-api.bookings.errors.allBookingsMustUseSameCreditAccount": undefined;
  "bookings-api.bookings.errors.allBookingsMustUseSamePaymentMethod": undefined;
  "bookings-api.bookings.errors.amountMustBeGreater": undefined;
  "bookings-api.bookings.errors.automaticApprovalIsRequired": undefined;
  "bookings-api.bookings.errors.availabilitiesDaysFieldError": undefined;
  "bookings-api.bookings.errors.bookingHasNoAccessCode": undefined;
  "bookings-api.bookings.errors.bookingResourceUpdateForbidden": undefined;
  "bookings-api.bookings.errors.bookingsMustNotClash": undefined;
  "bookings-api.bookings.errors.businessHoursMustBeValid": undefined;
  "bookings-api.bookings.errors.cancellationPeriodMustBeGreater": undefined;
  "bookings-api.bookings.errors.cancellationRatesCannotExceed": undefined;
  "bookings-api.bookings.errors.cannotScheduleResourceBeforeActiveFinishes": undefined;
  "bookings-api.bookings.errors.capacityMustBeGreater": undefined;
  "bookings-api.bookings.errors.chargeForBookingNotProcessed": undefined;
  "bookings-api.bookings.errors.childResourcesHaveChildren": { childrenWithChildren: string | number };
  "bookings-api.bookings.errors.childResourcesNotFound": { missingChildren: string | number };
  "bookings-api.bookings.errors.companyDataNotFound": undefined;
  "bookings-api.bookings.errors.companyIsNotFlexOperator": undefined;
  "bookings-api.bookings.errors.deletionTimeCannotBeInPast": undefined;
  "bookings-api.bookings.errors.didNotReceiveResponse": undefined;
  "bookings-api.bookings.errors.durationMinimumIs": { index: string | number };
  "bookings-api.bookings.errors.endDateCantBeBeforeStart": undefined;
  "bookings-api.bookings.errors.exceedMaximumRecurringBookings": { count: string | number };
  "bookings-api.bookings.errors.exceedMaximumTimeAllowed": { count: string | number };
  "bookings-api.bookings.errors.failedCreateBooking": undefined;
  "bookings-api.bookings.errors.failedCreateCustomer": undefined;
  "bookings-api.bookings.errors.failedCreateIntent": undefined;
  "bookings-api.bookings.errors.failedLoadUserInfo": undefined;
  "bookings-api.bookings.errors.failedLoadYourProfileInfo": undefined;
  "bookings-api.bookings.errors.failedToRefund": undefined;
  "bookings-api.bookings.errors.halfDayDurationMustBeGreater": undefined;
  "bookings-api.bookings.errors.invalidAccessGroup": undefined;
  "bookings-api.bookings.errors.invalidAddOnAmountRefund": { name: string | number, amount: string | number };
  "bookings-api.bookings.errors.invalidAmountOfAdjustment": { amount: string | number };
  "bookings-api.bookings.errors.invalidAmountPartialRefund": { currency: string, price: number, item: string | number };
  "bookings-api.bookings.errors.invalidAvailabilityDateRange": undefined;
  "bookings-api.bookings.errors.invalidBookingAmountRefund": { amount: string | number };
  "bookings-api.bookings.errors.invalidBookingDiscountPercentage": undefined;
  "bookings-api.bookings.errors.invalidDuration": undefined;
  "bookings-api.bookings.errors.invalidEarlyBookingDiscountNumberOfDays": undefined;
  "bookings-api.bookings.errors.invalidEarlyBookingInput": undefined;
  "bookings-api.bookings.errors.invalidEndFormatOnAvailability": { index: string | number };
  "bookings-api.bookings.errors.invalidResourceStatus": undefined;
  "bookings-api.bookings.errors.invalidStartFormatOnAvailability": { index: string | number };
  "bookings-api.bookings.errors.invalidTimePeriod": undefined;
  "bookings-api.bookings.errors.maximumTimeBeforeStart": undefined;
  "bookings-api.bookings.errors.minimumBookingTimeMustBeLess": { index: string | number };
  "bookings-api.bookings.errors.minimumTimeBeforeStart": undefined;
  "bookings-api.bookings.errors.minimumTimeBeforeStartMustBeLess": undefined;
  "bookings-api.bookings.errors.missingCurrencyInformation": undefined;
  "bookings-api.bookings.errors.missingResourceSiteInformation": undefined;
  "bookings-api.bookings.errors.missingSiteInformation": undefined;
  "bookings-api.bookings.errors.moreThanOneCompanyEditBookingPolicy": undefined;
  "bookings-api.bookings.errors.moreThanOneDefaultEditBookingPolicy": undefined;
  "bookings-api.bookings.errors.nameIsRequired": undefined;
  "bookings-api.bookings.errors.noDestinationSpecified": undefined;
  "bookings-api.bookings.errors.noManuallyApprovedCreditBookings": undefined;
  "bookings-api.bookings.errors.noManuallyApprovedCreditCardBookings": undefined;
  "bookings-api.bookings.errors.noPermissionToCreateResources": undefined;
  "bookings-api.bookings.errors.noPermissionToInformation": undefined;
  "bookings-api.bookings.errors.noRefundForAddonsWithPriceOverride": undefined;
  "bookings-api.bookings.errors.nothingToCharge": undefined;
  "bookings-api.bookings.errors.paymentAdjustmentDisabled": undefined;
  "bookings-api.bookings.errors.paymentAdjustmentMaxDateError": { max: string | number };
  "bookings-api.bookings.errors.paymentAdjustmentNotApplicableForCancelledBooking": undefined;
  "bookings-api.bookings.errors.paymentAdjustmentNotApplicableForFreeBooking": undefined;
  "bookings-api.bookings.errors.paymentRatesMustBeGreater": undefined;
  "bookings-api.bookings.errors.paymentRejected": { responseCode: string | number };
  "bookings-api.bookings.errors.paymentRejectedMessage": { responseMessage: string | number, responseCode: string | number };
  "bookings-api.bookings.errors.permissionDenied": undefined;
  "bookings-api.bookings.errors.pleaseProvideCreditInformation": undefined;
  "bookings-api.bookings.errors.pleaseSetStartDate": undefined;
  "bookings-api.bookings.errors.preparationTimeAfterMustBeGreater": undefined;
  "bookings-api.bookings.errors.preparationTimeBeforeMustBeGreater": undefined;
  "bookings-api.bookings.errors.provideOneAvailability": undefined;
  "bookings-api.bookings.errors.provideSlotsDuration": undefined;
  "bookings-api.bookings.errors.reasonIsRequired": undefined;
  "bookings-api.bookings.errors.refundFailed": undefined;
  "bookings-api.bookings.errors.refundFailedMessage": { refundMessage: string | number };
  "bookings-api.bookings.errors.resourceAlreadyDeleted": undefined;
  "bookings-api.bookings.errors.resourceAlreadyExist": undefined;
  "bookings-api.bookings.errors.resourceCannotBeOwnChild": undefined;
  "bookings-api.bookings.errors.resourceNoPublished": undefined;
  "bookings-api.bookings.errors.resourceNotAccessible": undefined;
  "bookings-api.bookings.errors.resourceNotFound": undefined;
  "bookings-api.bookings.errors.resourceNotPendingDeletion": undefined;
  "bookings-api.bookings.errors.resourceTypeIsRequired": undefined;
  "bookings-api.bookings.errors.resourceTypeNotFound": undefined;
  "bookings-api.bookings.errors.resourceTypeOldError": undefined;
  "bookings-api.bookings.errors.resourceWithNameExists": undefined;
  "bookings-api.bookings.errors.selectEndTime": undefined;
  "bookings-api.bookings.errors.selectStartTime": undefined;
  "bookings-api.bookings.errors.specifyAtLeastPneWeekday": { index: string | number };
  "bookings-api.bookings.errors.startTimeDateMismatch": undefined;
  "bookings-api.bookings.errors.startTimeMustBeBefore": { index: string | number };
  "bookings-api.bookings.errors.unableAllocateCurrencyCode": undefined;
  "bookings-api.bookings.errors.unableAllocateRegionId": undefined;
  "bookings-api.bookings.errors.unableFindCreditAccountInfo": undefined;
  "bookings-api.bookings.errors.unableFindCreditCardInfo": undefined;
  "bookings-api.bookings.errors.unableFindSiteInformation": undefined;
  "bookings-api.bookings.errors.unableFindUserDestination": undefined;
  "bookings-api.bookings.errors.unableFindYourSiteInformation": undefined;
  "bookings-api.bookings.errors.unableIdentifyPaymentGateway": undefined;
  "bookings-api.bookings.errors.unableRefundBookingNotPaid": undefined;
  "bookings-api.bookings.errors.unableToChangeResourceCompany": undefined;
  "bookings-api.bookings.errors.unableToFindChargeInfo": undefined;
  "bookings-api.bookings.errors.unableToLoadBookingSetting": undefined;
  "bookings-api.bookings.errors.unableToLoadClientInfo": undefined;
  "bookings-api.bookings.errors.vendorAccountNotConfigured": undefined;
  "bookings-api.bookings.errors.youCantApplyDifferentRates": undefined;
  "bookings-api.bookings.errors.youCantCancelRates": undefined;
  "bookings-api.bookings.errors.youCantShareResourceOutside": undefined;
  "bookings-api.bookings.errors.youMustConfigureBaseRates": undefined;
  "bookings-api.bookings.errors.youMustProvideRecipientDetails": undefined;
  "bookings-api.bookings.errors.youMustSelectInvoiceRecipient": undefined;
  "bookings-api.bookings.errors.youMustSelectPaymentMethods": undefined;
  "bookings-api.bookings.errors.youRefundFailed": undefined;
  "bookings-api.bookings.errors.youRefundFailedMessage": { refundMessage: string | number };
  "bookings-api.bookings.errors.yourPaymentRejected": { responseCode: string | number };
  "bookings-api.bookings.errors.yourPaymentRejectedMessage": { responseMessage: string | number, responseCode: string | number };
  "bookings-api.bookings.messages.SharedFacilitiesWithSameName": undefined;
  "bookings-api.bookings.messages.bookingAdditionalInvoiceDetailsIsTooLong": undefined;
  "bookings-api.bookings.messages.bookingAlreadyCancelled": undefined;
  "bookings-api.bookings.messages.bookingBlocked": undefined;
  "bookings-api.bookings.messages.bookingCanOnlyPaidByInvoice": undefined;
  "bookings-api.bookings.messages.bookingCannotBeCancelled": undefined;
  "bookings-api.bookings.messages.bookingCannotBeEditedRefundIssued": undefined;
  "bookings-api.bookings.messages.bookingCantBeChanged": undefined;
  "bookings-api.bookings.messages.bookingCantBePaidByCreditcard": undefined;
  "bookings-api.bookings.messages.bookingCantBrMultipleDays": undefined;
  "bookings-api.bookings.messages.bookingConflict": undefined;
  "bookings-api.bookings.messages.bookingContainsInvalidAddOns": undefined;
  "bookings-api.bookings.messages.bookingContainsInvalidAddOnsQuantities": undefined;
  "bookings-api.bookings.messages.bookingContainsMultipleSelections": undefined;
  "bookings-api.bookings.messages.bookingDeclined": undefined;
  "bookings-api.bookings.messages.bookingIsInThePast": undefined;
  "bookings-api.bookings.messages.bookingMissingMandatoryAddOns": undefined;
  "bookings-api.bookings.messages.bookingNotFound": undefined;
  "bookings-api.bookings.messages.bookingNotRequirePayment": undefined;
  "bookings-api.bookings.messages.bookingsContainsDuplicate": undefined;
  "bookings-api.bookings.messages.declinedReasonRequired": undefined;
  "bookings-api.bookings.messages.endTimeBeforeResourceDeleted": undefined;
  "bookings-api.bookings.messages.invalidRefundOrAdjustment": undefined;
  "bookings-api.bookings.messages.invoiceMethodCannotUpdated": undefined;
  "bookings-api.bookings.messages.manualApprovalCreditCard": undefined;
  "bookings-api.bookings.messages.maximumAddOnExceeded": undefined;
  "bookings-api.bookings.messages.maximumBookingIs": { time: number };
  "bookings-api.bookings.messages.minimumBookingIs": { time: number };
  "bookings-api.bookings.messages.mustSelectPaymentMethod": undefined;
  "bookings-api.bookings.messages.noAbleToLookResource": undefined;
  "bookings-api.bookings.messages.noNegativePrice": undefined;
  "bookings-api.bookings.messages.noPermissionsForAnotherUser": undefined;
  "bookings-api.bookings.messages.noPermissionsForCreditAccount": undefined;
  "bookings-api.bookings.messages.noPermissionsToBook": undefined;
  "bookings-api.bookings.messages.noPermissionsToChange": undefined;
  "bookings-api.bookings.messages.notPendingApproval": undefined;
  "bookings-api.bookings.messages.paymentMethodCannotUpdated": undefined;
  "bookings-api.bookings.messages.pleaseAcceptTermsAndConditions": undefined;
  "bookings-api.bookings.messages.pleaseContactPropertyManager": undefined;
  "bookings-api.bookings.messages.pleaseProvideBillingCustomerInfo": undefined;
  "bookings-api.bookings.messages.pleaseProvideCardInfo": undefined;
  "bookings-api.bookings.messages.pleaseSelectCreditAccount": undefined;
  "bookings-api.bookings.messages.priceOverridePendingApproval": undefined;
  "bookings-api.bookings.messages.recipientNotFound": undefined;
  "bookings-api.bookings.messages.resourceFeatureIsUsed": { resourceName: string | number };
  "bookings-api.bookings.messages.resourceFeatureNotEditable": undefined;
  "bookings-api.bookings.messages.resourceFeatureUnableToDelete": undefined;
  "bookings-api.bookings.messages.resourceFeatureWithSameName": undefined;
  "bookings-api.bookings.messages.resourceNameCantBeBookedEarly": { resourceName: string | number };
  "bookings-api.bookings.messages.resourceNameCantBeBookedFar": { resourceName: string | number };
  "bookings-api.bookings.messages.resourceNotAvailable": { resourceName: string | number };
  "bookings-api.bookings.messages.resourceTypeIsUsed": { resourceName: string | number };
  "bookings-api.bookings.messages.resourceTypeNotEditable": undefined;
  "bookings-api.bookings.messages.resourceTypeUnableToDelete": undefined;
  "bookings-api.bookings.messages.resourceTypeWithSameName": undefined;
  "bookings-api.bookings.messages.resourceUsingResourceType": { resourceName: string | number, count: number };
  "bookings-api.bookings.messages.roomConfigurationIsUsed": { resourceName: string | number };
  "bookings-api.bookings.messages.roomConfigurationNotEditable": undefined;
  "bookings-api.bookings.messages.roomConfigurationNotExist": undefined;
  "bookings-api.bookings.messages.roomConfigurationNotExistTryAgain": undefined;
  "bookings-api.bookings.messages.roomConfigurationUnableToDelete": undefined;
  "bookings-api.bookings.messages.roomConfigurationWithSameName": undefined;
  "bookings-api.bookings.messages.sharedFacilitiesIsUsed": { resourceName: string | number };
  "bookings-api.bookings.messages.sharedFacilitiesNotEditable": undefined;
  "bookings-api.bookings.messages.sharedFacilitiesUnableToDelete": undefined;
  "bookings-api.bookings.messages.startTimeBeforeEndTime": undefined;
  "bookings-api.bookings.messages.thisBookingCannotBeChanged": undefined;
  "bookings-api.bookings.messages.unableFindAvailability": { resourceName: string | number };
  "bookings-api.bookings.messages.unableFindCompanyInformation": undefined;
  "bookings-api.bookings.messages.unableFindSiteInformation": undefined;
  "bookings-api.bookings.messages.unableToFindClientInformation": undefined;
  "bookings-api.bookings.messages.unableToFindResourceFeature": undefined;
  "bookings-api.bookings.messages.unableToFindResourceSharedFacilities": undefined;
  "bookings-api.bookings.messages.unableToFindResourceType": undefined;
  "bookings-api.bookings.messages.unableToFindRoomConfiguration": undefined;
  "bookings-api.bookings.messages.unableToFindSharedFacilities": undefined;
  "bookings-api.bookings.messages.unableToFindUser": undefined;
  "bookings-api.bookings.messages.unableToProcessPayment": undefined;
  "bookings-api.bookings.messages.unableToProcessRefund": undefined;
  "bookings-api.bookings.messages.userNotAPropertyManager": undefined;
  "bookings-api.bookings.messages.youCantUseSelectedMethod": undefined;
  "bookings-api.bookings.messages.youDoNotHavePermissionMakeBooking": undefined;
  "bookings-api.bookings.messages.youMustAcceptCancellationTerms": undefined;
  "bookings-api.bookings.messages.youMustProvideDetailsOfInvoice": undefined;
  "bookings-api.bookings.messages.youMustSelectRecipient": undefined;
  "bookings-api.common.errors.noDestinationSpecified": undefined;
  "bookings-api.siteCompany.errors.cannotModifySiteCompanyMaxAppointmentCreationMonths": undefined;
  "bookings-api.siteCompany.errors.siteCompanyMaxAppointmentCreationMonthsLimitError": { min: string | number, max: string | number };
  "bookings-api.visitorManagementSiteSettings.errors.siteMaxAppointmentCreationMonthsLimitError": { min: string | number, max: string | number };
  "bookings-api.visitors.errors.accessDeniedCompanyList": undefined;
  "bookings-api.visitors.errors.accessDeniedForReception": undefined;
  "bookings-api.visitors.errors.accessDeniedHostSuggestions": undefined;
  "bookings-api.visitors.errors.accessDeniedHostSuggestionsForCompany": undefined;
  "bookings-api.visitors.errors.appointmentDateExceedsAllowedBoundary": { date: string | number };
  "bookings-api.visitors.errors.createDeniedForReception": undefined;
  "bookings-api.visitors.errors.deleteActiveAppointment": undefined;
  "bookings-api.visitors.errors.editExpiredAppointment": undefined;
  "bookings-api.visitors.errors.emptyCompany": undefined;
  "bookings-api.visitors.errors.failedToFindBarrierConfig": { uuid: string | number };
  "bookings-api.visitors.errors.hostAndCompanyNotProvided": undefined;
  "bookings-api.visitors.errors.hostCompanyIsNull": undefined;
  "bookings-api.visitors.errors.hostNotFound": undefined;
  "bookings-api.visitors.errors.incorrectCompany": undefined;
  "bookings-api.visitors.errors.invalidAppointmentCompany": undefined;
  "bookings-api.visitors.errors.invalidAppointmentOwner": undefined;
  "bookings-api.visitors.errors.invalidAppointmentSite": undefined;
  "bookings-api.visitors.errors.invalidLocationForReception": undefined;
  "bookings-api.visitors.errors.invalidReceptionCompany": undefined;
  "bookings-api.visitors.errors.invalidReceptionCurrentCompany": undefined;
  "bookings-api.visitors.errors.invalidReceptionSite": undefined;
  "bookings-api.visitors.errors.notPropertyManager": undefined;
  "bookings-api.visitors.errors.notWorkplaceManager": undefined;
  "bookings-api.visitors.errors.receptionNotExistsOnSite": undefined;
  "bookings-api.visitors.errors.recurringAppointmentValidationErrorDifferentFailedToDetermineRecurringUpdateType": undefined;
  "bookings-api.visitors.errors.recurringAppointmentValidationErrorDifferentFailedToFindRecurringSettings": undefined;
  "bookings-api.visitors.errors.recurringAppointmentValidationErrorDifferentRecurringTypes": undefined;
  "bookings-api.visitors.errors.recurringAppointmentValidationErrorIsWalkIn": undefined;
  "bookings-api.visitors.errors.recurringAppointmentValidationErrorMissingProperty": { paramNames: string | number };
  "bookings-api.visitors.errors.recurringAppointmentValidationErrorNotRecurring": undefined;
  "bookings-api.visitors.errors.statusChangeDenied": undefined;
  "integrations-api.errors.common.forbidden": { contactEmail: string | number };
  "integrations-api.errors.common.incompleteUserContext": undefined;
  "integrations-api.errors.gallagher.mobileCredentialCreateFailed": { email: string | number, contactEmail: string | number };
  "integrations-api.errors.gallagher.requestFailed": { contactEmail: string | number };
  "integrations-api.errors.gallagher.siteNotFound": undefined;
  "integrations-api.errors.gallagher.unavailable": { contactEmail: string | number };
  "integrations-api.errors.gallagher.userNotFound": { email: string | number, contactEmail: string | number };
  "integrations-api.errors.openpath.apiError": { statusText: string | number, status: string | number, contactEmail: string | number };
  "integrations-api.errors.openpath.incompleteUserContext": undefined;
  "integrations-api.errors.openpath.mobileCredentialAllocateFailed": { email: string | number };
  "integrations-api.errors.openpath.mobileCredentialCreateFailed": { email: string | number };
  "integrations-api.errors.openpath.mobileCredentialTypeNotFound": undefined;
  "integrations-api.errors.openpath.setupMobileTokenGenerateFailed": { email: string | number };
  "integrations-api.errors.openpath.siteNotFound": undefined;
  "integrations-api.errors.openpath.unavailable": { contactEmail: string | number };
  "integrations-api.errors.openpath.userEmailNotFound": undefined;
  "integrations-api.errors.openpath.userIncomplete": { email: string | number, siteEmail: string | number };
  "integrations-api.errors.openpath.userNotFound": { email: string | number, siteEmail: string | number };
  "integrations-api.errors.openpath.userOrgIdNotFound": { email: string | number, siteEmail: string | number };
  "integrations-api.errors.swiftconnect.apiError": { statusText: string | number, status: string | number, contactEmail: string | number };
  "integrations-api.errors.swiftconnect.pendingAccess": { contactEmail: string | number };
  "integrations-api.errors.swiftconnect.siteNotFound": undefined;
  "integrations-api.errors.swiftconnect.unavailable": { contactEmail: string | number };
  "integrations-api.errors.swiftconnect.userEmailNotFound": undefined;
  "operations-api.bookings.errors.addOnNotFound": undefined;
  "operations-api.bookings.errors.additionalChargeUnsuccessful": { currency: string, price: number, reference: string | number };
  "operations-api.bookings.errors.adjustmentNotFound": undefined;
  "operations-api.bookings.errors.amountMustBeGreater": undefined;
  "operations-api.bookings.errors.businessHoursMustBeValid": undefined;
  "operations-api.bookings.errors.cancellationPeriodMustBeGreater": undefined;
  "operations-api.bookings.errors.cancellationRatesCannotExceed": undefined;
  "operations-api.bookings.errors.cannotScheduleResourceBeforeActiveFinishes": undefined;
  "operations-api.bookings.errors.capacityMustBeGreater": undefined;
  "operations-api.bookings.errors.chargeForBookingNotProcessed": undefined;
  "operations-api.bookings.errors.childResourcesHaveChildren": { childrenWithChildren: string | number };
  "operations-api.bookings.errors.childResourcesNotFound": { missingChildren: string | number };
  "operations-api.bookings.errors.companyDataNotFound": undefined;
  "operations-api.bookings.errors.companyIsNotFlexOperator": undefined;
  "operations-api.bookings.errors.deletionTimeCannotBeInPast": undefined;
  "operations-api.bookings.errors.didNotReceiveResponse": undefined;
  "operations-api.bookings.errors.durationMinimumIs": { index: string | number };
  "operations-api.bookings.errors.endDateCantBeBeforeStart": undefined;
  "operations-api.bookings.errors.failedCreateCustomer": undefined;
  "operations-api.bookings.errors.failedCreateIntent": undefined;
  "operations-api.bookings.errors.failedLoadUserInfo": undefined;
  "operations-api.bookings.errors.failedLoadYourProfileInfo": undefined;
  "operations-api.bookings.errors.failedToRefund": undefined;
  "operations-api.bookings.errors.halfDayDurationMustBeGreater": undefined;
  "operations-api.bookings.errors.invalidAddOnAmountRefund": { name: string | number, amount: string | number };
  "operations-api.bookings.errors.invalidAmountOfAdjustment": { amount: string | number };
  "operations-api.bookings.errors.invalidAmountPartialRefund": { currency: string, price: number, item: string | number };
  "operations-api.bookings.errors.invalidAvailabilityDateRange": undefined;
  "operations-api.bookings.errors.invalidBookingAmountRefund": { amount: string | number };
  "operations-api.bookings.errors.invalidDuration": undefined;
  "operations-api.bookings.errors.invalidEndFormatOnAvailability": { index: string | number };
  "operations-api.bookings.errors.invalidResourceStatus": undefined;
  "operations-api.bookings.errors.invalidStartFormatOnAvailability": { index: string | number };
  "operations-api.bookings.errors.invalidTimePeriod": undefined;
  "operations-api.bookings.errors.maximumTimeBeforeStart": undefined;
  "operations-api.bookings.errors.minimumBookingTimeMustBeLess": { index: string | number };
  "operations-api.bookings.errors.minimumTimeBeforeStart": undefined;
  "operations-api.bookings.errors.minimumTimeBeforeStartMustBeLess": undefined;
  "operations-api.bookings.errors.missingCurrencyInformation": undefined;
  "operations-api.bookings.errors.missingSiteInformation": undefined;
  "operations-api.bookings.errors.nameIsRequired": undefined;
  "operations-api.bookings.errors.noDestinationSpecified": undefined;
  "operations-api.bookings.errors.noManuallyApprovedCreditBookings": undefined;
  "operations-api.bookings.errors.noManuallyApprovedCreditCardBookings": undefined;
  "operations-api.bookings.errors.noPermissionToCreateResources": undefined;
  "operations-api.bookings.errors.noPermissionToInformation": undefined;
  "operations-api.bookings.errors.nothingToCharge": undefined;
  "operations-api.bookings.errors.paymentAdjustmentDisabled": undefined;
  "operations-api.bookings.errors.paymentAdjustmentMaxDateError": { max: string | number };
  "operations-api.bookings.errors.paymentAdjustmentNotApplicableForCancelledBooking": undefined;
  "operations-api.bookings.errors.paymentAdjustmentNotApplicableForFreeBooking": undefined;
  "operations-api.bookings.errors.paymentRatesMustBeGreater": undefined;
  "operations-api.bookings.errors.paymentRejected": { responseCode: string | number };
  "operations-api.bookings.errors.paymentRejectedMessage": { responseMessage: string | number, responseCode: string | number };
  "operations-api.bookings.errors.permissionDenied": undefined;
  "operations-api.bookings.errors.pleaseProvideCreditInformation": undefined;
  "operations-api.bookings.errors.pleaseSetStartDate": undefined;
  "operations-api.bookings.errors.preparationTimeAfterMustBeGreater": undefined;
  "operations-api.bookings.errors.preparationTimeBeforeMustBeGreater": undefined;
  "operations-api.bookings.errors.provideOneAvailability": undefined;
  "operations-api.bookings.errors.provideSlotsDuration": undefined;
  "operations-api.bookings.errors.reasonIsRequired": undefined;
  "operations-api.bookings.errors.refundFailed": undefined;
  "operations-api.bookings.errors.refundFailedMessage": { refundMessage: string | number };
  "operations-api.bookings.errors.resourceAlreadyDeleted": undefined;
  "operations-api.bookings.errors.resourceAlreadyExist": undefined;
  "operations-api.bookings.errors.resourceCannotBeOwnChild": undefined;
  "operations-api.bookings.errors.resourceNoPublished": undefined;
  "operations-api.bookings.errors.resourceNotFound": undefined;
  "operations-api.bookings.errors.resourceNotPendingDeletion": undefined;
  "operations-api.bookings.errors.resourceTypeIsRequired": undefined;
  "operations-api.bookings.errors.resourceTypeNotFound": undefined;
  "operations-api.bookings.errors.resourceTypeOldError": undefined;
  "operations-api.bookings.errors.resourceWithNameExists": undefined;
  "operations-api.bookings.errors.specifyAtLeastPneWeekday": { index: string | number };
  "operations-api.bookings.errors.startTimeDateMismatch": undefined;
  "operations-api.bookings.errors.startTimeMustBeBefore": { index: string | number };
  "operations-api.bookings.errors.unableAllocateCurrencyCode": undefined;
  "operations-api.bookings.errors.unableAllocateRegionId": undefined;
  "operations-api.bookings.errors.unableFindCreditAccountInfo": undefined;
  "operations-api.bookings.errors.unableFindCreditCardInfo": undefined;
  "operations-api.bookings.errors.unableFindSiteInformation": undefined;
  "operations-api.bookings.errors.unableFindUserDestination": undefined;
  "operations-api.bookings.errors.unableFindYourSiteInformation": undefined;
  "operations-api.bookings.errors.unableIdentifyPaymentGateway": undefined;
  "operations-api.bookings.errors.unableRefundBookingNotPaid": undefined;
  "operations-api.bookings.errors.unableToChangeResourceCompany": undefined;
  "operations-api.bookings.errors.unableToFindChargeInfo": undefined;
  "operations-api.bookings.errors.unableToLoadBookingSetting": undefined;
  "operations-api.bookings.errors.unableToLoadClientInfo": undefined;
  "operations-api.bookings.errors.vendorAccountNotConfigured": undefined;
  "operations-api.bookings.errors.youCantApplyDifferentRates": undefined;
  "operations-api.bookings.errors.youCantCancelRates": undefined;
  "operations-api.bookings.errors.youCantShareResourceOutside": undefined;
  "operations-api.bookings.errors.youMustConfigureBaseRates": undefined;
  "operations-api.bookings.errors.youMustProvideRecipientDetails": undefined;
  "operations-api.bookings.errors.youMustSelectInvoiceRecipient": undefined;
  "operations-api.bookings.errors.youMustSelectPaymentMethods": undefined;
  "operations-api.bookings.errors.youRefundFailed": undefined;
  "operations-api.bookings.errors.youRefundFailedMessage": { refundMessage: string | number };
  "operations-api.bookings.errors.yourPaymentRejected": { responseCode: string | number };
  "operations-api.bookings.errors.yourPaymentRejectedMessage": { responseMessage: string | number, responseCode: string | number };
  "operations-api.bookings.messages.SharedFacilitiesWithSameName": undefined;
  "operations-api.bookings.messages.bookingAlreadyCancelled": undefined;
  "operations-api.bookings.messages.bookingBlocked": undefined;
  "operations-api.bookings.messages.bookingCanOnlyPaidByInvoice": undefined;
  "operations-api.bookings.messages.bookingCannotBeCancelled": undefined;
  "operations-api.bookings.messages.bookingCannotBeEditedRefundIssued": undefined;
  "operations-api.bookings.messages.bookingCantBeChanged": undefined;
  "operations-api.bookings.messages.bookingCantBrMultipleDays": undefined;
  "operations-api.bookings.messages.bookingConflict": undefined;
  "operations-api.bookings.messages.bookingContainsInvalidAddOns": undefined;
  "operations-api.bookings.messages.bookingContainsInvalidAddOnsQuantities": undefined;
  "operations-api.bookings.messages.bookingContainsMultipleSelections": undefined;
  "operations-api.bookings.messages.bookingDeclined": undefined;
  "operations-api.bookings.messages.bookingIsInThePast": undefined;
  "operations-api.bookings.messages.bookingMissingMandatoryAddOns": undefined;
  "operations-api.bookings.messages.bookingNotFound": undefined;
  "operations-api.bookings.messages.bookingNotRequirePayment": undefined;
  "operations-api.bookings.messages.bookingsContainsDuplicate": undefined;
  "operations-api.bookings.messages.declinedReasonRequired": undefined;
  "operations-api.bookings.messages.endTimeBeforeResourceDeleted": undefined;
  "operations-api.bookings.messages.invoiceMethodCannotUpdated": undefined;
  "operations-api.bookings.messages.manualApprovalCreditCard": undefined;
  "operations-api.bookings.messages.maximumAddOnExceeded": undefined;
  "operations-api.bookings.messages.maximumBookingIs": { time: number };
  "operations-api.bookings.messages.minimumBookingIs": { time: number };
  "operations-api.bookings.messages.mustSelectPaymentMethod": undefined;
  "operations-api.bookings.messages.noAbleToLookResource": undefined;
  "operations-api.bookings.messages.noPermissionsForAnotherUser": undefined;
  "operations-api.bookings.messages.noPermissionsForCreditAccount": undefined;
  "operations-api.bookings.messages.noPermissionsToBook": undefined;
  "operations-api.bookings.messages.noPermissionsToChange": undefined;
  "operations-api.bookings.messages.notPendingApproval": undefined;
  "operations-api.bookings.messages.paymentMethodCannotUpdated": undefined;
  "operations-api.bookings.messages.pleaseAcceptTermsAndConditions": undefined;
  "operations-api.bookings.messages.pleaseContactPropertyManager": undefined;
  "operations-api.bookings.messages.pleaseProvideBillingCustomerInfo": undefined;
  "operations-api.bookings.messages.pleaseProvideCardInfo": undefined;
  "operations-api.bookings.messages.pleaseSelectCreditAccount": undefined;
  "operations-api.bookings.messages.recipientNotFound": undefined;
  "operations-api.bookings.messages.resourceFeatureIsUsed": { resourceName: string | number };
  "operations-api.bookings.messages.resourceFeatureNotEditable": undefined;
  "operations-api.bookings.messages.resourceFeatureUnableToDelete": undefined;
  "operations-api.bookings.messages.resourceFeatureWithSameName": undefined;
  "operations-api.bookings.messages.resourceNameCantBeBookedEarly": { resourceName: string | number };
  "operations-api.bookings.messages.resourceNameCantBeBookedFar": { resourceName: string | number };
  "operations-api.bookings.messages.resourceNotAvailable": { resourceName: string | number };
  "operations-api.bookings.messages.resourceTypeIsUsed": { resourceName: string | number };
  "operations-api.bookings.messages.resourceTypeNotEditable": undefined;
  "operations-api.bookings.messages.resourceTypeUnableToDelete": undefined;
  "operations-api.bookings.messages.resourceTypeWithSameName": undefined;
  "operations-api.bookings.messages.resourceUsingResourceType": { resourceName: string | number, count: number };
  "operations-api.bookings.messages.roomConfigurationIsUsed": { resourceName: string | number };
  "operations-api.bookings.messages.roomConfigurationNotEditable": undefined;
  "operations-api.bookings.messages.roomConfigurationNotExist": undefined;
  "operations-api.bookings.messages.roomConfigurationNotExistTryAgain": undefined;
  "operations-api.bookings.messages.roomConfigurationUnableToDelete": undefined;
  "operations-api.bookings.messages.roomConfigurationWithSameName": undefined;
  "operations-api.bookings.messages.sharedFacilitiesIsUsed": { resourceName: string | number };
  "operations-api.bookings.messages.sharedFacilitiesNotEditable": undefined;
  "operations-api.bookings.messages.sharedFacilitiesUnableToDelete": undefined;
  "operations-api.bookings.messages.startTimeBeforeEndTime": undefined;
  "operations-api.bookings.messages.thisBookingCannotBeChanged": undefined;
  "operations-api.bookings.messages.unableFindAvailability": { resourceName: string | number };
  "operations-api.bookings.messages.unableFindCompanyInformation": undefined;
  "operations-api.bookings.messages.unableFindSiteInformation": undefined;
  "operations-api.bookings.messages.unableToFindClientInformation": undefined;
  "operations-api.bookings.messages.unableToFindResourceFeature": undefined;
  "operations-api.bookings.messages.unableToFindResourceSharedFacilities": undefined;
  "operations-api.bookings.messages.unableToFindResourceType": undefined;
  "operations-api.bookings.messages.unableToFindRoomConfiguration": undefined;
  "operations-api.bookings.messages.unableToFindSharedFacilities": undefined;
  "operations-api.bookings.messages.unableToFindUser": undefined;
  "operations-api.bookings.messages.unableToProcessPayment": undefined;
  "operations-api.bookings.messages.unableToProcessRefund": undefined;
  "operations-api.bookings.messages.userNotAPropertyManager": undefined;
  "operations-api.bookings.messages.youCantUseSelectedMethod": undefined;
  "operations-api.bookings.messages.youDoNotHavePermissionMakeBooking": undefined;
  "operations-api.bookings.messages.youMustAcceptCancellationTerms": undefined;
  "operations-api.bookings.messages.youMustProvideDetailsOfInvoice": undefined;
  "operations-api.bookings.messages.youMustSelectRecipient": undefined;
  "operations-api.common.errors.noDestinationSpecified": undefined;
  "operations-api.public-api.companyHasNoReceptions": { uuid: string | number };
  "operations-api.public-api.companyHasNoReceptionsOrUnauthorized": { uuid: string | number };
  "operations-api.public-api.incorrectDateTime": undefined;
  "operations-api.public-api.provideKeyOrUuid": undefined;
  "operations-api.public-api.userHasNoCompany": { uuid: string | number };
  "operations-api.public-api.userHasNoDestinations": { uuid: string | number };
  "operations-api.public-api.userHasNoFirstName": { uuid: string | number };
  "operations-api.public-api.userHasNoLastName": { uuid: string | number };
  "operations-api.public-api.userProfileNotFound": { uuid: string | number };
  "operations-api.siteCompany.errors.cannotModifySiteCompanyMaxAppointmentCreationMonths": undefined;
  "operations-api.siteCompany.errors.siteCompanyMaxAppointmentCreationMonthsLimitError": { min: string | number, max: string | number };
  "operations-api.visitorManagementSiteSettings.errors.siteMaxAppointmentCreationMonthsLimitError": { min: string | number, max: string | number };
  "operations-api.visitors.errors.accessDeniedCompanyList": undefined;
  "operations-api.visitors.errors.accessDeniedForReception": undefined;
  "operations-api.visitors.errors.accessDeniedHostSuggestions": undefined;
  "operations-api.visitors.errors.accessDeniedHostSuggestionsForCompany": undefined;
  "operations-api.visitors.errors.appointmentDateExceedsAllowedBoundary": { date: string | number };
  "operations-api.visitors.errors.createDeniedForReception": undefined;
  "operations-api.visitors.errors.createVisitorAppointmentForBookingBookingAlreadyExists": { bookingUuid: string | number, appointmentUuid: string | number };
  "operations-api.visitors.errors.deleteActiveAppointment": undefined;
  "operations-api.visitors.errors.editExpiredAppointment": undefined;
  "operations-api.visitors.errors.emptyCompany": undefined;
  "operations-api.visitors.errors.failedLoadBookingsProfileInfo": undefined;
  "operations-api.visitors.errors.failedToFindBarrierConfig": { uuid: string | number };
  "operations-api.visitors.errors.hostAndCompanyNotProvided": undefined;
  "operations-api.visitors.errors.hostCompanyIsNull": undefined;
  "operations-api.visitors.errors.hostNotFound": undefined;
  "operations-api.visitors.errors.incorrectCompany": undefined;
  "operations-api.visitors.errors.invalidAppointmentCompany": undefined;
  "operations-api.visitors.errors.invalidAppointmentOwner": undefined;
  "operations-api.visitors.errors.invalidAppointmentSite": undefined;
  "operations-api.visitors.errors.invalidLocationForReception": undefined;
  "operations-api.visitors.errors.invalidReceptionCompany": undefined;
  "operations-api.visitors.errors.invalidReceptionCurrentCompany": undefined;
  "operations-api.visitors.errors.invalidReceptionSite": undefined;
  "operations-api.visitors.errors.notPropertyManager": undefined;
  "operations-api.visitors.errors.notWorkplaceManager": undefined;
  "operations-api.visitors.errors.receptionNotExistsOnSite": undefined;
  "operations-api.visitors.errors.recurringAppointmentValidationErrorDifferentFailedToDetermineRecurringUpdateType": undefined;
  "operations-api.visitors.errors.recurringAppointmentValidationErrorDifferentFailedToFindRecurringSettings": undefined;
  "operations-api.visitors.errors.recurringAppointmentValidationErrorDifferentRecurringTypes": undefined;
  "operations-api.visitors.errors.recurringAppointmentValidationErrorIsWalkIn": undefined;
  "operations-api.visitors.errors.recurringAppointmentValidationErrorMissingProperty": { paramNames: string | number };
  "operations-api.visitors.errors.recurringAppointmentValidationErrorNotRecurring": undefined;
  "operations-api.visitors.errors.statusChangeDenied": undefined;
  "operations-api.visitors.errors.visitorAppointmentForBookingNotFound": { bookingUuid: string | number };
  "operations-api.visitors.errors.visitorAppointmentStartTimeIsGreaterThanEndTime": undefined;
  "permissions-api.errors.equiemOnly": { roleName: string | number };
  "permissions-api.errors.grantDenied": { roleName: string | number, resourceName: string | number };
  "permissions-api.errors.invalidRoleTuple": { tuple: string | number };
  "permissions-api.errors.noRoleMatchingTuple": { tuple: string | number };
  "profile-api.errors.oneTimePassword.cannotGenerateCode": undefined;
  "profile-api.errors.oneTimePassword.cannotGenerateCodeWithErrorCode": { errorCode: string | number };
  "profile-api.errors.oneTimePassword.expiredCode": undefined;
  "profile-api.errors.oneTimePassword.incorrectCode": undefined;
  "profile-api.errors.oneTimePassword.invalidEmail": undefined;
  "profile-api.errors.oneTimePassword.invalidNumber": undefined;
  "profile-api.errors.oneTimePassword.numberOptedOut": undefined;
  "profile-api.errors.oneTimePassword.smsDisabled": undefined;
  "profile-api.errors.oneTimePassword.unknownIssue": undefined;
  "profile-api.errors.oneTimePassword.unverifiedEmail": undefined;
  "request-management-api.errors.buildingCategoryNonExistance": undefined;
  "request-management-api.errors.buildingNotFound": undefined;
  "request-management-api.errors.buildingsNotFound": undefined;
  "request-management-api.errors.buildingsNotFoundBySiteContext": undefined;
  "request-management-api.errors.cafmConfigurationAlreadyExistsForSiteForProvider": undefined;
  "request-management-api.errors.cafmConfigurationDeleteFailed": undefined;
  "request-management-api.errors.cafmConfigurationFailedToGeneratePassword": undefined;
  "request-management-api.errors.cafmConfigurationNotFound": { uuid: string | number };
  "request-management-api.errors.cafmConfigurationUpsertFailed": undefined;
  "request-management-api.errors.cafmConfigurationUpsertFailedCannotChangeProviderType": undefined;
  "request-management-api.errors.categoryCafmConfigurationDeleteFailed": undefined;
  "request-management-api.errors.categoryCafmConfigurationLinkAlreadyExists": undefined;
  "request-management-api.errors.categoryCafmConfigurationLinkAlreadyExistsForBuilding": undefined;
  "request-management-api.errors.categoryCafmConfigurationNotFound": { uuid: string | number };
  "request-management-api.errors.categoryCafmConfigurationSiteInconsistent": undefined;
  "request-management-api.errors.categoryCannotUpdateNonExistent": undefined;
  "request-management-api.errors.categoryNameTaken": undefined;
  "request-management-api.errors.categoryNotActive": undefined;
  "request-management-api.errors.categoryNotFound": undefined;
  "request-management-api.errors.categoryTypeNotFound": undefined;
  "request-management-api.errors.companyNotFound": undefined;
  "request-management-api.errors.completionDateBeforeReportedError": undefined;
  "request-management-api.errors.createCategoryFailed": undefined;
  "request-management-api.errors.createSubCategoryFailed": undefined;
  "request-management-api.errors.dateCompletedStatusError": undefined;
  "request-management-api.errors.dateFutureError": undefined;
  "request-management-api.errors.dateReportedFutureError": undefined;
  "request-management-api.errors.deleteCategoryFailed": undefined;
  "request-management-api.errors.deleteSubCategoryFailed": undefined;
  "request-management-api.errors.descriptionIsRequired": undefined;
  "request-management-api.errors.differentSiteAccessError": undefined;
  "request-management-api.errors.iconRequired": undefined;
  "request-management-api.errors.loadCategoryPresetsFailed": undefined;
  "request-management-api.errors.nameCannotBeEmpty": undefined;
  "request-management-api.errors.noAccessToDoThisOperation": undefined;
  "request-management-api.errors.noAccessToEditRequest": undefined;
  "request-management-api.errors.noAccessToReassignRequest": undefined;
  "request-management-api.errors.noAccessToViewRequest": undefined;
  "request-management-api.errors.presetNotFound": undefined;
  "request-management-api.errors.queueAgentsRequired": undefined;
  "request-management-api.errors.queueAlreadyActive": undefined;
  "request-management-api.errors.queueAlreadyInactive": undefined;
  "request-management-api.errors.queueNotFound": undefined;
  "request-management-api.errors.reportedDateAfterCompletionError": undefined;
  "request-management-api.errors.reporterCompanyNotFoundError": undefined;
  "request-management-api.errors.requestHasChanged": undefined;
  "request-management-api.errors.requestMissingCategoryOrSubcategory": undefined;
  "request-management-api.errors.requestNotFound": undefined;
  "request-management-api.errors.requestWasAlreadySent": undefined;
  "request-management-api.errors.siteNotFound": undefined;
  "request-management-api.errors.spaceAndCategoryInDifferentCompany": undefined;
  "request-management-api.errors.spaceNotFound": undefined;
  "request-management-api.errors.statusIsUsed": undefined;
  "request-management-api.errors.statusMinimumRequired": undefined;
  "request-management-api.errors.statusNotFound": undefined;
  "request-management-api.errors.statusWithSameName": undefined;
  "request-management-api.errors.subCategoryCannotUpdateNonExistent": undefined;
  "request-management-api.errors.subCategoryNameTaken": undefined;
  "request-management-api.errors.subcategoryMissmatchError": undefined;
  "request-management-api.errors.subcategoryNotFound": undefined;
  "request-management-api.errors.updateCategoryFailed": undefined;
  "request-management-api.errors.updateSubCategoryFailed": undefined;
  "request-management-api.errors.userNotFound": undefined;
  "syndicate-api.registrationFailure.captchaFailed": undefined;
  "syndicate-api.registrationFailure.companyDoesNotExist": undefined;
  "syndicate-api.registrationFailure.companyNotProvided": undefined;
  "syndicate-api.registrationFailure.completionEmailSent": { siteName: string | number };
  "syndicate-api.registrationFailure.contactSupport": undefined;
  "syndicate-api.registrationFailure.createAccountFailed": undefined;
  "syndicate-api.registrationFailure.createProfileFailed": undefined;
  "syndicate-api.registrationFailure.invalidEmail": undefined;
  "syndicate-api.registrationFailure.invalidToken": undefined;
  "syndicate-api.registrationFailure.registrationEmailSendFailed": undefined;
  "syndicate-api.registrationFailure.registrationFailedInAuth0": undefined;
  "syndicate-api.registrationFailure.saveProfileFailed": undefined;
  "syndicate-api.registrationFailure.siteNotExist": undefined;
  "syndicate-api.registrationFailure.siteNotFound": undefined;
  "syndicate-api.registrationFailure.unknownError": undefined;
  "syndicate-api.registrationFailure.urlDetectedInName": undefined;
  "syndicate-api.registrationFailure.userExists": undefined;
  "syndicate-api.registrationFailure.userExistsPersonal": undefined;
  "syndicate-api.registrationFailure.userNotExist": undefined;
  "syndicate-api.ubiparkFailure.noUbiparkCredentials": undefined;
  "syndicate-api.ubiparkFailure.ubiparkUserNotCreated": undefined;
  "syndicate-api.ubiparkFailure.unableToProcessRequest": undefined;
  "syndicate-api.ubiparkFailure.userNotAuthenticated": undefined;
  "syndicate-api.ubiparkFailure.userNotFound": undefined;
  "syndicate-api.userDestinationStatus.pendingEmailConfirmation": undefined;
  "syndicate-api.userDestinationStatus.pendingVerification": undefined;
  "syndicate-api.userDestinationStatus.pendingVerificationAndEmailConfirmation": undefined;
  "zero.graphql.errors.FORBIDDEN": undefined;
  "zero.graphql.errors.INTERNAL_SERVER_ERROR": undefined;
  "zero.graphql.errors.NOT_IMPLEMENTED": undefined;
  "zero.graphql.scalars.colour": undefined;
  "zero.graphql.scalars.datetime": undefined;
  "zero.graphql.scalars.email": undefined;
  "zero.graphql.scalars.jwt": undefined;
  "zero.graphql.scalars.mobilenumber.format": undefined;
  "zero.graphql.scalars.mobilenumber.not-mobile": undefined;
  "zero.graphql.scalars.time": undefined;
  "zero.graphql.scalars.timezone": undefined;
  "zero.graphql.scalars.uri": undefined;
  "zero.graphql.scalars.uuid": undefined;
}

/** @public */
export type ApplicationMessages = {
  "common.acceptTermsAndConditions": undefined;
  "common.activity": undefined;
  "common.add": undefined;
  "common.addDescription": undefined;
  "common.areYouSure": undefined;
  "common.attachments": undefined;
  "common.back": undefined;
  "common.building": undefined;
  "common.buildings": undefined;
  "common.cancel": undefined;
  "common.cancelMessage": undefined;
  "common.cancelNo": undefined;
  "common.change": undefined;
  "common.close": undefined;
  "common.company": undefined;
  "common.confirm": undefined;
  "common.create": undefined;
  "common.createNew": undefined;
  "common.date": undefined;
  "common.delete": undefined;
  "common.description": undefined;
  "common.dismiss": undefined;
  "common.done": undefined;
  "common.edit": undefined;
  "common.email": undefined;
  "common.emptyNone": undefined;
  "common.findAPerson": undefined;
  "common.firstName": undefined;
  "common.iAcceptThe": undefined;
  "common.image": undefined;
  "common.invalidDateFormat": undefined;
  "common.lastName": undefined;
  "common.level": undefined;
  "common.loading": undefined;
  "common.me": undefined;
  "common.nameRequired": undefined;
  "common.none": undefined;
  "common.pageIsNotAvailableAnymore": undefined;
  "common.perItemCost": { price: string | number };
  "common.quantity": undefined;
  "common.refreshing": undefined;
  "common.remove": undefined;
  "common.reply": undefined;
  "common.returnToHome": undefined;
  "common.saved": undefined;
  "common.saving": undefined;
  "common.search": undefined;
  "common.select": undefined;
  "common.selectIcon": undefined;
  "common.selectType": undefined;
  "common.send": undefined;
  "common.site": undefined;
  "common.someone": undefined;
  "common.somethingWrong": undefined;
  "common.somethingWrongTryAgainLater": undefined;
  "common.status": undefined;
  "common.step": { step: string | number };
  "common.submitting": undefined;
  "common.summary": undefined;
  "common.termsAndConditions": undefined;
  "common.termsAndConditionsTitle": undefined;
  "common.today": undefined;
  "common.tomorrow": undefined;
  "common.unknownError": undefined;
  "common.unknownErrorTryLater": undefined;
  "common.untitled": undefined;
  "common.uploadFile": undefined;
  "common.uploaded": undefined;
  "common.viewAll": undefined;
  "common.yesCancel": undefined;
  "common.yesClose": undefined;
  "common.yesRemove": undefined;
  "contentManagement.deleteFileAttachment": undefined;
  "contentManagement.deleteFileAttachmentMessage": undefined;
  "home.widgets.cancelNo": undefined;
  "lib.addCard": undefined;
  "lib.addNewCard": undefined;
  "lib.back": undefined;
  "lib.cancel": undefined;
  "lib.cardDetailsExist": undefined;
  "lib.cardDetailsExistandDefault": undefined;
  "lib.cardSaved": undefined;
  "lib.cardSavedAndDefault": undefined;
  "lib.contentNotAvailable": undefined;
  "lib.dismiss": undefined;
  "lib.failedToEncryptCookie": { name: string | number, value: string | number };
  "lib.returnToHome": undefined;
  "lib.save": undefined;
  "lib.shortDescription": undefined;
  "lib.somethingWentWrongPlsTryAgain": undefined;
  "lib.sorryUnknownError": undefined;
  "lib.stripeElementIsNotInitialised": undefined;
  "lib.stripeProviderNotLoaded": undefined;
  "lib.tokenisationFailed": undefined;
  "lib.unknownError": undefined;
  "lib.untitled": undefined;
  "main.accept": undefined;
  "main.acceptAndContinue": undefined;
  "main.acceptTermsAndConditionsSeePrivacyPolicy": undefined;
  "main.accessDenied": undefined;
  "main.active": undefined;
  "main.activeSubscriptions": undefined;
  "main.addBookingToCalendar": undefined;
  "main.addEventToCalendar": undefined;
  "main.addMethod": undefined;
  "main.addMoreDates": undefined;
  "main.addNewCard": undefined;
  "main.addNewDebitOrCreditCard": undefined;
  "main.addNoteAboutOrder": undefined;
  "main.addPicture": undefined;
  "main.addToCalendar": undefined;
  "main.addToCart": undefined;
  "main.agreeToTermsAndConditions": undefined;
  "main.all": undefined;
  "main.allEvents": undefined;
  "main.allEventsFor": { dateDisplay: string | number };
  "main.allUpToDate": undefined;
  "main.alwaysEnabled": undefined;
  "main.apply": undefined;
  "main.arrivingInAbout": { count: number };
  "main.asap": undefined;
  "main.attendees": undefined;
  "main.availability": undefined;
  "main.back": undefined;
  "main.backToStore": { storeTitle: string | number };
  "main.bookedTime": undefined;
  "main.bookingDetails": undefined;
  "main.bookingForDateAlreadyInCart": { date: string | number, time: string | number };
  "main.bookingNumber": undefined;
  "main.bookingSummary": undefined;
  "main.bookings": undefined;
  "main.bookingsNumber": { count: number };
  "main.browserNotifications": undefined;
  "main.buildingInfo": undefined;
  "main.cancel": undefined;
  "main.cancelBooking": undefined;
  "main.cancelBookingConfirmation": undefined;
  "main.cancelBookingError": undefined;
  "main.cancelBookingSuccess": undefined;
  "main.cancelRsvp": undefined;
  "main.cancelRsvpConfirmation": undefined;
  "main.cancelRsvpSuccess": undefined;
  "main.cancelSubscription": undefined;
  "main.cancelSubscriptionConfirm": undefined;
  "main.cancelSubscriptionSuccess": undefined;
  "main.cancelled": undefined;
  "main.cardDeletedNotification": undefined;
  "main.cartContainsBookingsFromVendorMsg": { count: number, vendor: string | number };
  "main.cartContainsBookingsOnItemAddMsg": { count: number };
  "main.cartContainsBookingsOnSubscriptionAddMsg": { count: number };
  "main.cartContainsItemsFromVendorMsg": { count: number, vendor: string | number };
  "main.cartContainsItemsOnBookingAddMsg": { count: number };
  "main.cartContainsItemsOnSubscriptionAddMsg": { count: number };
  "main.cartContainsSessionsFromDifferentBookingMsg": undefined;
  "main.cartContainsSubscriptionMsg": undefined;
  "main.cartContainsSubscriptionsFromVendorMsg": { count: number, vendor: string | number };
  "main.cartContainsSubscriptionsOnBookingAddMsg": { count: number };
  "main.cartContainsSubscriptionsOnItemAddMsg": { count: number };
  "main.cartIsEmpty": undefined;
  "main.categories": undefined;
  "main.changeEmail": undefined;
  "main.changePicture": undefined;
  "main.chargedMonthly": undefined;
  "main.chargedWeekly": undefined;
  "main.chargedHalfYearly": undefined;
  "main.chargedYearly": undefined;
  "main.checkInboxToVerifyEmailNotification": undefined;
  "main.checkPaymentDetailsAndTryAgain": undefined;
  "main.checkout": undefined;
  "main.clearCart": undefined;
  "main.clearCartAndAddBookingsInsteadConfirmation": { count: number };
  "main.clearCartAndAddItemsInsteadConfirmation": { count: number };
  "main.clearCartAndAddSubscriptionsInsteadConfirmation": { count: number };
  "main.clearCartAndTryAgain": undefined;
  "main.close": undefined;
  "main.closed": undefined;
  "main.comment": undefined;
  "main.comments": undefined;
  "main.completeTheFieldsError": { count: number };
  "main.completed": undefined;
  "main.completedOrdersAndEventsWillAppearHere": undefined;
  "main.confirm": undefined;
  "main.confirmationDeleteComment": undefined;
  "main.contactUs": undefined;
  "main.contactUsDescription": undefined;
  "main.contentComingSoon": undefined;
  "main.contentCouldNotBeFoundError": undefined;
  "main.contentDoesNotExistOrNotAccessible": undefined;
  "main.continueShopping": undefined;
  "main.cookieSettings": undefined;
  "main.cookiesAcceptedNotification": undefined;
  "main.cookiesPolicy": undefined;
  "main.copied": undefined;
  "main.copyCode": undefined;
  "main.copyOfPaymentReceiptWillBeSent": { email: string | number };
  "main.copyOfReceiptSentTo": undefined;
  "main.current": undefined;
  "main.currentOrders": undefined;
  "main.currentOrdersAndEvents": undefined;
  "main.dayOfWeekFriday": undefined;
  "main.dayOfWeekMonday": undefined;
  "main.dayOfWeekSaturday": undefined;
  "main.dayOfWeekSunday": undefined;
  "main.dayOfWeekThursday": undefined;
  "main.dayOfWeekTuesday": undefined;
  "main.dayOfWeekWednesday": undefined;
  "main.deal": undefined;
  "main.decline": undefined;
  "main.declineAndSignOut": undefined;
  "main.default": undefined;
  "main.delete": undefined;
  "main.deleteAccount": undefined;
  "main.deleteAccountDescription": undefined;
  "main.deleteComment": undefined;
  "main.deleteCreditCardConfirmation": undefined;
  "main.deliveringTo": { location: string | number };
  "main.deliveryDate": undefined;
  "main.deliveryDetails": undefined;
  "main.deliveryLocation": undefined;
  "main.deliveryTime": undefined;
  "main.details": undefined;
  "main.discount": undefined;
  "main.discountCode": undefined;
  "main.discountCodeAddedToCheckoutSuccess": undefined;
  "main.downloads": undefined;
  "main.edit": undefined;
  "main.editYourDetails": undefined;
  "main.email": undefined;
  "main.emailVerificationDescription": undefined;
  "main.enterDiscountCode": undefined;
  "main.enterEmailAndInviteWillBeSent": undefined;
  "main.error": undefined;
  "main.estimatedDeliveryDate": undefined;
  "main.estimatedDeliveryTime": undefined;
  "main.estimatedPickupDate": undefined;
  "main.estimatedPickupTime": undefined;
  "main.eventDetails": undefined;
  "main.eventFull": undefined;
  "main.eventPostedOn": { date: string | number };
  "main.eventRsvp": undefined;
  "main.eventTime": undefined;
  "main.events": undefined;
  "main.extremelyLikely": undefined;
  "main.extremelySatisfied": undefined;
  "main.extremelyUnsatisfied": undefined;
  "main.failedToLoadBookableProductError": undefined;
  "main.failedToLoadBookingError": undefined;
  "main.failedToLoadBuildingInfoPostError": undefined;
  "main.failedToLoadContentError": undefined;
  "main.failedToLoadDealError": undefined;
  "main.failedToLoadEventError": undefined;
  "main.failedToLoadEventPostError": undefined;
  "main.failedToLoadNewsPostError": undefined;
  "main.failedToLoadOccurrenceError": undefined;
  "main.failedToLoadOrderError": undefined;
  "main.failedToLoadProductError": undefined;
  "main.failedToLoadVendorError": undefined;
  "main.featureNotAvailableOnThisSite": undefined;
  "main.featured": undefined;
  "main.file": undefined;
  "main.firstName": undefined;
  "main.forMe": undefined;
  "main.free": undefined;
  "main.from": undefined;
  "main.functionalityCookiesActive": undefined;
  "main.functionalityCookiesDescription": undefined;
  "main.functionalityCookiesInactive": undefined;
  "main.functionalityCookiesTitle": undefined;
  "main.getAndroidApp": undefined;
  "main.getInTouchWithUs": undefined;
  "main.getIosApp": undefined;
  "main.goBackToStoreAndTryAgain": undefined;
  "main.goToCheckout": undefined;
  "main.goodAfternoon": undefined;
  "main.goodEvening": undefined;
  "main.goodMorning": undefined;
  "main.goodNight": undefined;
  "main.hereIsWhatIsTrending": undefined;
  "main.hi": { name: string | number };
  "main.history": undefined;
  "main.home": undefined;
  "main.howCanWeFurtherImproveYourExperience": undefined;
  "main.imageFormatError": undefined;
  "main.infoAboutBuildingIsComingSoon": undefined;
  "main.interestedInSeeingMore": undefined;
  "main.interests": undefined;
  "main.interestsDescription": undefined;
  "main.invalidEmailAddress": undefined;
  "main.inviteFailed": undefined;
  "main.inviteFailedEmailInvalid": undefined;
  "main.inviteFailedEmailInvited": undefined;
  "main.inviteSuccessful": undefined;
  "main.itemsNumber": { count: number };
  "main.lastName": undefined;
  "main.latest": undefined;
  "main.leaveNotesForSeller": undefined;
  "main.loadMore": undefined;
  "main.loadingOrder": undefined;
  "main.location": undefined;
  "main.logIn": undefined;
  "main.logOut": undefined;
  "main.manageSubscription": undefined;
  "main.maybeLater": undefined;
  "main.menu": undefined;
  "main.mobileNumber": undefined;
  "main.mobileNumberDescription": undefined;
  "main.mobileNumberExample": { mobileNumber: string | number };
  "main.mobileNumberIsRequired": undefined;
  "main.month": undefined;
  "main.moreItems": { count: number };
  "main.mostRelevant": undefined;
  "main.myAccount": undefined;
  "main.necessaryCookiesDescription": undefined;
  "main.necessaryCookiesTitle": undefined;
  "main.newEmail": undefined;
  "main.news": undefined;
  "main.newsPostedOn": { date: string | number };
  "main.next": undefined;
  "main.nextAvailable": undefined;
  "main.nextChargeDate": undefined;
  "main.no": undefined;
  "main.noCommentsAddYoursAbove": undefined;
  "main.noEventPost": undefined;
  "main.noEventsPlanned": undefined;
  "main.noPaymentMethod": undefined;
  "main.noResults": { term: string | number };
  "main.noUpcomingEvents": undefined;
  "main.none": undefined;
  "main.noneFound": undefined;
  "main.notAbleToGetPermissionToEnableNotifications": undefined;
  "main.notAtAllLikely": undefined;
  "main.notFoundError": undefined;
  "main.notesForSeller": undefined;
  "main.notesForSellerExample": undefined;
  "main.notificationUponcompletion": undefined;
  "main.notificationsCount": { count: number };
  "main.onDateTime": { date: string | number, time: string | number };
  "main.oneOfChoicesCurrentlyNotAvailable": undefined;
  "main.open": undefined;
  "main.open247": undefined;
  "main.optInToReceiveNewsletterUpdates": undefined;
  "main.optInToReceivePushNotificationUpdates": undefined;
  "main.optional": undefined;
  "main.orderAndEventHistory": undefined;
  "main.orderDetails": undefined;
  "main.orderHistory": undefined;
  "main.orderId": { id: string | number };
  "main.orderSummary": undefined;
  "main.orderingItemRequiresDetails": { fieldTitle: string | number };
  "main.ordersViaThirdParty": undefined;
  "main.originalTotal": undefined;
  "main.otherSessions": { count: number };
  "main.ourUseOfCookiesDescription": undefined;
  "main.ourUseOfCookiesTitle": undefined;
  "main.pageNotAvailableAnymore": undefined;
  "main.paidInFull": undefined;
  "main.partOfCommunityCommentOrSeeMoreRegisterNow": { siteName: string | number };
  "main.partOfCommunityCommentRegisterNow": { siteName: string | number };
  "main.partOfCommunityRsvpForEventRegisterNow": { siteName: string | number };
  "main.paused": undefined;
  "main.payment": undefined;
  "main.paymentDetails": undefined;
  "main.paymentFailed": undefined;
  "main.paymentFailure": undefined;
  "main.paymentPending": undefined;
  "main.paymentSuccess": undefined;
  "main.pending": undefined;
  "main.pendingVerification": undefined;
  "main.perPerson": undefined;
  "main.performanceCookiesActive": undefined;
  "main.performanceCookiesDescription": undefined;
  "main.performanceCookiesInactive": undefined;
  "main.performanceCookiesTitle": undefined;
  "main.perksAndDeals": undefined;
  "main.pickupDate": undefined;
  "main.pickupFrom": { location: string | number };
  "main.pickupTime": undefined;
  "main.placeBooking": undefined;
  "main.placeBookingsNumber": { count: number };
  "main.placeOrder": undefined;
  "main.prev": undefined;
  "main.privacy": undefined;
  "main.privacyPolicy": undefined;
  "main.problemProcessingPayment": undefined;
  "main.productAddedToCartSuccess": { productName: string | number };
  "main.productCurrentlyNotAvailable": undefined;
  "main.productNoLongerAvailable": undefined;
  "main.productNotAvailableAtTheMoment": undefined;
  "main.profileDoesNotHaveCustomerIdError": undefined;
  "main.profileNotYetLoadedTryAgain": undefined;
  "main.profilePage": undefined;
  "main.profilePictureSavedNotification": undefined;
  "main.profileSavedNotification": undefined;
  "main.providePaymentDetailsError": undefined;
  "main.quantity": undefined;
  "main.quicklinks": undefined;
  "main.ratingHasBeenRecordedAs": undefined;
  "main.readMoreAboutCookiePolicy": undefined;
  "main.readyIn": undefined;
  "main.readyInAbout": { count: number };
  "main.rebook": undefined;
  "main.recurring": undefined;
  "main.referenceNumber": undefined;
  "main.refundedInFull": undefined;
  "main.register": undefined;
  "main.registerFor": { name: string | number };
  "main.registerNow": undefined;
  "main.registerOnOwnDevice": undefined;
  "main.registration": undefined;
  "main.rejected": undefined;
  "main.relatedInfo": undefined;
  "main.remove": undefined;
  "main.removeUnavailableItems": { count: number };
  "main.reorder": undefined;
  "main.reportingCurrentlyDisabled": undefined;
  "main.requestManagement": undefined;
  "main.required": undefined;
  "main.resendVerificationEmail": undefined;
  "main.restrictedAvailability": undefined;
  "main.retailers": undefined;
  "main.retryPayment": undefined;
  "main.returnToHome": undefined;
  "main.rsvp": undefined;
  "main.save": undefined;
  "main.scanQrCodeBelowToRegisterOnOwnDevice": undefined;
  "main.search": undefined;
  "main.selectDeliveryMethod": undefined;
  "main.selectDeliveryMethodError": undefined;
  "main.selectPreferredCard": undefined;
  "main.selectPreferredPaymentMethod": undefined;
  "main.selectionsBelowWillApplyToAllSelectedSessions": undefined;
  "main.sendEmail": undefined;
  "main.sendInvite": undefined;
  "main.setAsDefault": undefined;
  "main.shoppingCart": undefined;
  "main.signingIn": undefined;
  "main.signingInAs": { email: string | number };
  "main.soldOut": undefined;
  "main.somethingUnexpectedWentWrongTryAgainError": undefined;
  "main.somethingWentWrongError": undefined;
  "main.somethingWentWrongTryAgainError": undefined;
  "main.sortBy": undefined;
  "main.startNewCart": undefined;
  "main.startShopping": undefined;
  "main.stillProcessingPayment": undefined;
  "main.submitComment": undefined;
  "main.subscriptionDetails": undefined;
  "main.subscriptionIsCurrentlyPaused": undefined;
  "main.subscriptionsNumber": { count: number };
  "main.subtotal": undefined;
  "main.tax": undefined;
  "main.terms": undefined;
  "main.termsAndConditions": undefined;
  "main.termsAndConditionsApply": undefined;
  "main.thanks": { name: string | number };
  "main.thereAreNoAvailableSessions": undefined;
  "main.thereAreNoCurrentOrders": undefined;
  "main.thereAreNoNews": undefined;
  "main.thereIsNoOrderHistory": undefined;
  "main.thirdPartyServiceProviders": undefined;
  "main.toReceiveUpdatesOptInToNewsLetter": undefined;
  "main.toReceiveUpdatesOptInToReceiveNewsletter": undefined;
  "main.today": undefined;
  "main.tomorrow": undefined;
  "main.total": undefined;
  "main.totalAttendees": undefined;
  "main.totalPaid": undefined;
  "main.totalPeopleIncludingYou": undefined;
  "main.totalToPay": undefined;
  "main.tradingHours": undefined;
  "main.transit": undefined;
  "main.transitScreenDisabledForSite": undefined;
  "main.trending": undefined;
  "main.unableToLoadProfileInfoError": undefined;
  "main.unableToProcessProfileInfoError": undefined;
  "main.unexpectedErrorOccurred": undefined;
  "main.uniqueCode": undefined;
  "main.unknown": undefined;
  "main.unknownBooking": undefined;
  "main.unknownCompany": undefined;
  "main.unknownErrorFetchingPageContactSupport": undefined;
  "main.unknownErrorOccurredTryAgainLater": undefined;
  "main.unknownOccurrenceType": undefined;
  "main.unsupportedContentTypeError": undefined;
  "main.untitled": undefined;
  "main.upcoming": undefined;
  "main.upcomingBookingsAndEvents": undefined;
  "main.upcomingEvents": undefined;
  "main.upcomingOrders": undefined;
  "main.upcomingOrdersAndEventsWillAppearHere": undefined;
  "main.uploadError": undefined;
  "main.useCode": undefined;
  "main.validatingOrderError": undefined;
  "main.vendorCancelledAndRefundedBooking": { vendorName: string | number };
  "main.vendorCancelledAndRefundedOrder": { vendorName: string | number };
  "main.vendorCompletedBooking": { vendorName: string | number };
  "main.vendorCompletedOrder": { vendorName: string | number };
  "main.vendorCurrentlyClosed": undefined;
  "main.vendorReceivedBooking": { vendorName: string | number };
  "main.vendorReceivedOrder": { vendorName: string | number };
  "main.vendorRejectedAndRefundedBooking": { vendorName: string | number };
  "main.vendorRejectedAndRefundedOrder": { vendorName: string | number };
  "main.vendors": undefined;
  "main.verified": undefined;
  "main.verify": undefined;
  "main.viewAllCurrentOrders": undefined;
  "main.viewAllOrderHistory": undefined;
  "main.viewDetails": undefined;
  "main.viewEvent": undefined;
  "main.viewLessDetails": undefined;
  "main.viewMoreDetails": undefined;
  "main.viewOnMap": undefined;
  "main.viewProgress": undefined;
  "main.viewReceipt": undefined;
  "main.viewShoppingCart": undefined;
  "main.viewStatus": undefined;
  "main.viewYourCurrentBookings": undefined;
  "main.visitorAppointments": undefined;
  "main.weHaveUpdatedTermsAndConditionsAndPrivacyPolicy": undefined;
  "main.webFeedback": undefined;
  "main.week": undefined;
  "main.halfYear": undefined;
  "main.year": undefined;
  "main.yes": undefined;
  "main.youWillBeProvidedWithAdditionalInfoAfterPurchase": undefined;
  "main.yourBooking": undefined;
  "main.yourCartIsEmpty": undefined;
  "main.yourComment": undefined;
  "main.yourCommentAnonymous": undefined;
  "main.yourDetails": undefined;
  "main.yourOrder": undefined;
  "requests.activity.assigneeChanged": undefined;
  "requests.activity.attachmentAdded": { name: string | number };
  "requests.activity.attachmentRemoved": { name: string | number };
  "requests.activity.categoryChanged": undefined;
  "requests.activity.dateCompletedChanged": undefined;
  "requests.activity.dateReportedChanged": undefined;
  "requests.activity.descriptionChanged": undefined;
  "requests.activity.everyone": undefined;
  "requests.activity.internalTeam": undefined;
  "requests.activity.locationChanged": undefined;
  "requests.activity.publicMessageConfirm": undefined;
  "requests.activity.publicMessageConfirmation": undefined;
  "requests.activity.publicMessageConfirmationRM": undefined;
  "requests.activity.reporterChanged": undefined;
  "requests.activity.requestClosed": undefined;
  "requests.activity.requestOpened": undefined;
  "requests.activity.statusChanged": undefined;
  "requests.activity.subCategoryAdded": undefined;
  "requests.activity.subCategoryChanged": undefined;
  "requests.activity.subCategoryRemoved": undefined;
  "requests.activity.toEveryone": undefined;
  "requests.activity.toInternalTeam": undefined;
  "requests.activity.watcherAdded": undefined;
  "requests.activity.watcherRemoved": undefined;
  "requests.allRequests": undefined;
  "requests.assignee": undefined;
  "requests.attachments.incorrectFileSize": undefined;
  "requests.attachments.incorrectUploadedFile": undefined;
  "requests.attachments.validationRule": undefined;
  "requests.category.addCategory": undefined;
  "requests.category.addNewCategory": undefined;
  "requests.category.addSubcategory": undefined;
  "requests.category.alreadyExists": undefined;
  "requests.category.buildingsSearchPlaceholder": undefined;
  "requests.category.categories": undefined;
  "requests.category.categoryName": undefined;
  "requests.category.categoryTypeLabel": undefined;
  "requests.category.categoryTypePlaceholder": undefined;
  "requests.category.configureCategories": undefined;
  "requests.category.deleteCategorySuccess": { categoryName: string | number };
  "requests.category.deleteSubCategorySuccess": { subCategoryName: string | number };
  "requests.category.description": undefined;
  "requests.category.edit": undefined;
  "requests.category.newCategoryAdded": undefined;
  "requests.category.newSubCategoryAdded": undefined;
  "requests.category.noCategoryHasBeenAdded": undefined;
  "requests.category.noSubCategoryHasBeenAdded": undefined;
  "requests.category.pleaseSelectACategory": undefined;
  "requests.category.subCategoryDescription": undefined;
  "requests.category.subcategories": undefined;
  "requests.category.types.accessSecurity": undefined;
  "requests.category.types.amenities": undefined;
  "requests.category.types.buildingMaintenance": undefined;
  "requests.category.types.buildingManagement": undefined;
  "requests.category.types.cctv": undefined;
  "requests.category.types.cleaning": undefined;
  "requests.category.types.customerService": undefined;
  "requests.category.types.electrical": undefined;
  "requests.category.types.escalators": undefined;
  "requests.category.types.fabric": undefined;
  "requests.category.types.fireSafety": undefined;
  "requests.category.types.furnitureEquipment": undefined;
  "requests.category.types.generalMaintenance": undefined;
  "requests.category.types.healthSafety": undefined;
  "requests.category.types.heatingCooling": undefined;
  "requests.category.types.itSystems": undefined;
  "requests.category.types.landscaping": undefined;
  "requests.category.types.lifts": undefined;
  "requests.category.types.lighting": undefined;
  "requests.category.types.mechanicalElectrical": undefined;
  "requests.category.types.miscellaneousOther": undefined;
  "requests.category.types.parking": undefined;
  "requests.category.types.pestControl": undefined;
  "requests.category.types.plants": undefined;
  "requests.category.types.plumbingDrainage": undefined;
  "requests.category.types.toilets": undefined;
  "requests.category.types.wasteRecycling": undefined;
  "requests.category.types.waterManagement": undefined;
  "requests.create.category": undefined;
  "requests.create.deleteNewRequest": undefined;
  "requests.create.deleteNewRequestMessage": undefined;
  "requests.create.details": undefined;
  "requests.create.error": undefined;
  "requests.create.location": undefined;
  "requests.create.newRequest": undefined;
  "requests.create.requestCreatedMessage": { reference: string | number };
  "requests.create.requestSubmitted": undefined;
  "requests.create.reviewRequest": undefined;
  "requests.create.reviewYourRequest": undefined;
  "requests.create.searchLevel": undefined;
  "requests.create.selectBuilding": undefined;
  "requests.create.selectCategory": undefined;
  "requests.create.selectLevel": undefined;
  "requests.create.selectSpace": undefined;
  "requests.create.submitRequest": undefined;
  "requests.details.assignee": undefined;
  "requests.details.building": undefined;
  "requests.details.category": undefined;
  "requests.details.categoryAndSubcategory": undefined;
  "requests.details.dateCompleted": undefined;
  "requests.details.dateReported": undefined;
  "requests.details.editRequest": { reference: string | number };
  "requests.details.level": undefined;
  "requests.details.location": undefined;
  "requests.details.noSubcategory": undefined;
  "requests.details.requestTitle": { reference: string | number };
  "requests.details.space": undefined;
  "requests.details.spaceIsRequired": undefined;
  "requests.details.status": undefined;
  "requests.details.subcategory": undefined;
  "requests.details.unwatch": undefined;
  "requests.details.viewActivities": undefined;
  "requests.details.watch": undefined;
  "requests.details.watcher": { count: number };
  "requests.details.watchers": undefined;
  "requests.icon": undefined;
  "requests.list.category": undefined;
  "requests.list.checkingAccessToBuilding": undefined;
  "requests.list.location": undefined;
  "requests.list.noResult": undefined;
  "requests.list.reportedBy": undefined;
  "requests.list.request": undefined;
  "requests.list.scrollDownToLoad": undefined;
  "requests.list.status": undefined;
  "requests.list.watching": undefined;
  "requests.openRequest": undefined;
  "requests.reporter": undefined;
  "requests.status.addStatus": undefined;
  "requests.status.configureStatuses": undefined;
  "requests.status.noStatusHasBeenAdded": undefined;
  "requests.status.pleaseSelectAStatusType": undefined;
  "requests.status.statusDeleted": undefined;
  "requests.status.statusType": undefined;
  "requests.status.statuses": undefined;
  "requests.status.type.COMPLETED": undefined;
  "requests.status.type.IN_PROGRESS": undefined;
  "requests.status.type.NOT_STARTED": undefined;
  "requests.tab.all": undefined;
  "requests.tab.allCompanyRequests": { company: string | number };
  "requests.tab.allRequests": undefined;
  "requests.tab.assignedToMe": undefined;
  "requests.tab.myRequests": undefined;
  "requests.tab.watching": undefined;
  "requests.thereAreNoResultsAvailable": { query: string | number };
  "requests.titleLong": undefined;
  "requests.titleShort": undefined;
  "requests.unassigned": undefined;
  "visitors.OrWereLookingFor": undefined;
  "visitors.add": undefined;
  "visitors.addAnotherDate": undefined;
  "visitors.addVisitor": undefined;
  "visitors.addVisitors": undefined;
  "visitors.addVisitorsIntro": undefined;
  "visitors.addedVisitors": undefined;
  "visitors.appointmentCreateRecurringItemTitle": undefined;
  "visitors.appointmentForm.addVisitorInformation": undefined;
  "visitors.appointmentForm.editVisitorInformation": undefined;
  "visitors.appointmentForm.cannotEditBookingInformation": undefined;
  "visitors.appointmentForm.noteToReception": undefined;
  "visitors.appointmentForm.setVisitorAs": undefined;
  "visitors.appointmentForm.visitorInformation": undefined;
  "visitors.appointmentForm.visitorNoteToReception": undefined;
  "visitors.appointmentForm.visitorNoteToReceptionPlaceholder": undefined;
  "visitors.appointmentForm.visitorNoteToReceptionValidation": undefined;
  "visitors.appointmentForm.visitorTypeValidation": undefined;
  "visitors.appointmentForm.visitorsValidation": undefined;
  "visitors.appointmentOccurence": undefined;
  "visitors.appointmentPreviewRecurringText": { repeatType: string | number, recurringEndDate: string | number, times: string | number };
  "visitors.appointmentRecurrence": undefined;
  "visitors.appointmentRecurring": undefined;
  "visitors.appointmentRecurringDeleteRecurringAppointmentHeader": undefined;
  "visitors.appointmentRecurringDeleteThisAndFollowing": undefined;
  "visitors.appointmentRecurringDeleteThisOne": undefined;
  "visitors.appointmentRecurringEditRecurringAppointmentHeader": undefined;
  "visitors.appointmentRecurringLast": undefined;
  "visitors.appointmentRecurringOccursEndsAfter": undefined;
  "visitors.appointmentRecurringOccursEndsOccurrences": undefined;
  "visitors.appointmentRecurringOccursEndsOn": undefined;
  "visitors.appointmentRecurringOccursEndsOnDay": { dayNumber: string | number };
  "visitors.appointmentRecurringOccursEndsOnLastOccurance": { weekday: string | number };
  "visitors.appointmentRecurringOccursEndsOnSameOccurance": { number: string | number, weekday: string | number };
  "visitors.appointmentRecurringOccursEveryDay": undefined;
  "visitors.appointmentRecurringOccursEveryDays": undefined;
  "visitors.appointmentRecurringOccursEveryMonth": undefined;
  "visitors.appointmentRecurringOccursEveryMonths": undefined;
  "visitors.appointmentRecurringOccursEveryWeek": undefined;
  "visitors.appointmentRecurringOccursEveryWeeks": undefined;
  "visitors.appointmentRecurringSettingsEnds": undefined;
  "visitors.appointmentRecurringSettingsFridayFriday": undefined;
  "visitors.appointmentRecurringSettingsFridayShort": undefined;
  "visitors.appointmentRecurringSettingsFridayShortened": undefined;
  "visitors.appointmentRecurringSettingsMondayLong": undefined;
  "visitors.appointmentRecurringSettingsMondayShort": undefined;
  "visitors.appointmentRecurringSettingsMondayShortened": undefined;
  "visitors.appointmentRecurringSettingsRepeatEvery": undefined;
  "visitors.appointmentRecurringSettingsRepeatOn": undefined;
  "visitors.appointmentRecurringSettingsSaturdayLong": undefined;
  "visitors.appointmentRecurringSettingsSaturdayShort": undefined;
  "visitors.appointmentRecurringSettingsSaturdayShortened": undefined;
  "visitors.appointmentRecurringSettingsSelector": { repeatType: string | number, recurringEndDate: string | number };
  "visitors.appointmentRecurringSettingsSelectorWeekly": { repeatType: string | number, days: string | number, recurringEndDate: string | number };
  "visitors.appointmentRecurringSettingsSundayLong": undefined;
  "visitors.appointmentRecurringSettingsSundayShort": undefined;
  "visitors.appointmentRecurringSettingsSundayShortened": undefined;
  "visitors.appointmentRecurringSettingsText": { repeatType: string | number, startTime: string | number, endTime: string | number, recurringEndDate: string | number, times: string | number };
  "visitors.appointmentRecurringSettingsThursdayLong": undefined;
  "visitors.appointmentRecurringSettingsThursdayShort": undefined;
  "visitors.appointmentRecurringSettingsThursdayShortened": undefined;
  "visitors.appointmentRecurringSettingsTuesdayLong": undefined;
  "visitors.appointmentRecurringSettingsTuesdayShort": undefined;
  "visitors.appointmentRecurringSettingsTuesdayShortened": undefined;
  "visitors.appointmentRecurringSettingsWednesdayLong": undefined;
  "visitors.appointmentRecurringSettingsWednesdayShort": undefined;
  "visitors.appointmentRecurringSettingsWednesdayShortened": undefined;
  "visitors.appointmentRepeat": undefined;
  "visitors.appointmentRepeatTypeDaily": undefined;
  "visitors.appointmentRepeatTypeMonthly": undefined;
  "visitors.appointmentRepeatTypeNone": undefined;
  "visitors.appointmentRepeatTypeWeekly": undefined;
  "visitors.appointmentSuccessfullyCancelled": undefined;
  "visitors.appointmentSuccessfullyCreated": undefined;
  "visitors.appointmentSuccessfullyEdited": undefined;
  "visitors.areYouSure": undefined;
  "visitors.areYouSureYouWantToCancelThisAppointment": undefined;
  "visitors.atLeastOneVisitorRequired": undefined;
  "visitors.cancel": undefined;
  "visitors.cancellingAppointment": undefined;
  "visitors.change": undefined;
  "visitors.changeHost": undefined;
  "visitors.charactersMaximumNum": { maxNum: string | number };
  "visitors.common.visitorsType": undefined;
  "visitors.company": undefined;
  "visitors.companyTooltip": undefined;
  "visitors.companyValidation": undefined;
  "visitors.confirmationReceive": undefined;
  "visitors.createNewHost": undefined;
  "visitors.createNewVisitor": undefined;
  "visitors.date": undefined;
  "visitors.dateFieldRequired": undefined;
  "visitors.dateMustBeInTheFuture": undefined;
  "visitors.dateWithin14DaysOfFirstMeeting": undefined;
  "visitors.description": undefined;
  "visitors.descriptionTooltipText": undefined;
  "visitors.done": undefined;
  "visitors.dontNotifyHost": undefined;
  "visitors.duration": undefined;
  "visitors.durationFieldRequired": undefined;
  "visitors.edit": undefined;
  "visitors.editAppointment": undefined;
  "visitors.emailAddress": undefined;
  "visitors.emailFieldInvalid": undefined;
  "visitors.emailNotificationForVisitors": undefined;
  "visitors.emailTooltip": undefined;
  "visitors.firstName": undefined;
  "visitors.firstNameFieldRequired": undefined;
  "visitors.firstNameValidation": undefined;
  "visitors.getStartedByCreatingANewAppointment": undefined;
  "visitors.host": undefined;
  "visitors.hostAdded": { name: string | number };
  "visitors.hostRemoved": { name: string | number };
  "visitors.inviteVisitorsToWorkplace": undefined;
  "visitors.lastName": undefined;
  "visitors.lastNameFieldRequired": undefined;
  "visitors.lastNameValidation": undefined;
  "visitors.level": undefined;
  "visitors.loading": undefined;
  "visitors.location": undefined;
  "visitors.locationFieldRequired": undefined;
  "visitors.newAppointment": undefined;
  "visitors.newHost": undefined;
  "visitors.noReceptionsAvailable": undefined;
  "visitors.noTakeMeBack": undefined;
  "visitors.none": undefined;
  "visitors.notifyHost": undefined;
  "visitors.notifyHostTooltip": { userType: string | number };
  "visitors.organizer": undefined;
  "visitors.placeholderDescription": undefined;
  "visitors.placeholderReception": undefined;
  "visitors.placeholderVisitorsNotes": undefined;
  "visitors.quickView": undefined;
  "visitors.receiveNotification": undefined;
  "visitors.reception": undefined;
  "visitors.receptionDisplayName": { prefix: string | number, suffix: string | number };
  "visitors.receptionNotes": { buildingName: string | number };
  "visitors.holdInLobbyHostEmailAlert": undefined;
  "visitors.holdInLobbyHostEmailRequired": undefined;
  "visitors.holdInLobby": undefined;
  "visitors.holdInLobbyLabel": undefined;
  "visitors.holdInLobbyTooltip": undefined;
  "visitors.receptionNotesTooltipText": undefined;
  "visitors.receptionNotesTooltipTextViewer": undefined;
  "visitors.remainingCharacters": { titleCharsCount: number };
  "visitors.remove": undefined;
  "visitors.selectCompany": undefined;
  "visitors.selectDuration": undefined;
  "visitors.selectLocation": undefined;
  "visitors.sharedNotifications": undefined;
  "visitors.somethingWentWrongPleaseTryAgainLater": undefined;
  "visitors.startTime": undefined;
  "visitors.startTimeInFuture": undefined;
  "visitors.submit": undefined;
  "visitors.suggestedHosts": undefined;
  "visitors.suggestedHostsEmpty": undefined;
  "visitors.suggestedVisitors": undefined;
  "visitors.time": undefined;
  "visitors.title": undefined;
  "visitors.titleEg": undefined;
  "visitors.titleFieldRequired": undefined;
  "visitors.titleMaxLengthValidation": { value: number };
  "visitors.today": undefined;
  "visitors.tomorrow": undefined;
  "visitors.type.requireAssistance": undefined;
  "visitors.type.vip": undefined;
  "visitors.visitorAppointments": undefined;
  "visitors.visitorNotes": undefined;
  "visitors.visitorNotesTooltipText": undefined;
  "visitors.visitorRemovalSuccess": { firstName: string | number, lastName: string | number };
  "visitors.visitors": undefined;
  "visitors.visitorsCount": { count: number };
  "visitors.yesCancel": undefined;
  "visitors.youDoNotHaveThePermissionToViewThisPage": undefined;
  "visitors.youHaveNoVisitorAppointmentsCurrently": undefined;
  "wayleader.doneWithVerifiedEmail": undefined;
  "wayleader.mailToGetSetUpForParking": undefined;
  "wayleader.mailToRequestWayLeadrRedirect": undefined;
  "wayleader.pleaseCheckYourInboxToVerifyYourEmail": undefined;
  "wayleader.retry": undefined;
  "wayleader.sendVerificationEmail": undefined;
  "wayleader.unableToSendVerificationEmail": undefined;
  "wayleader.verifyEmailAddress": undefined;
}

export const languages: Languages = {
  "cs": {
    "web-ng": async () => import("./../locales/cs/web-ng.json"),
    "server-messages": async () => import("./../locales/cs/server-messages.json"),
  },
  "de": {
    "web-ng": async () => import("./../locales/de/web-ng.json"),
    "server-messages": async () => import("./../locales/de/server-messages.json"),
  },
  "en": {
    "web-ng": enApplication,
    "server-messages": enServer,
  },
  "en-GB": {
    "web-ng": async () => import("./../locales/en_GB/web-ng.json"),
    "server-messages": async () => import("./../locales/en_GB/server-messages.json"),
  },
  "en-US": {
    "web-ng": async () => import("./../locales/en_US/web-ng.json"),
    "server-messages": async () => import("./../locales/en_US/server-messages.json"),
  },
  "es": {
    "web-ng": async () => import("./../locales/es/web-ng.json"),
    "server-messages": async () => import("./../locales/es/server-messages.json"),
  },
  "fr": {
    "web-ng": async () => import("./../locales/fr/web-ng.json"),
    "server-messages": async () => import("./../locales/fr/server-messages.json"),
  },
  "hu": {
    "web-ng": async () => import("./../locales/hu/web-ng.json"),
    "server-messages": async () => import("./../locales/hu/server-messages.json"),
  },
  "ja": {
    "web-ng": async () => import("./../locales/ja/web-ng.json"),
    "server-messages": async () => import("./../locales/ja/server-messages.json"),
  },
  "nl": {
    "web-ng": async () => import("./../locales/nl/web-ng.json"),
    "server-messages": async () => import("./../locales/nl/server-messages.json"),
  },
  "pl": {
    "web-ng": async () => import("./../locales/pl/web-ng.json"),
    "server-messages": async () => import("./../locales/pl/server-messages.json"),
  },
  "pt-PT": {
    "web-ng": async () => import("./../locales/pt_PT/web-ng.json"),
    "server-messages": async () => import("./../locales/pt_PT/server-messages.json"),
  },
  "ro": {
    "web-ng": async () => import("./../locales/ro/web-ng.json"),
    "server-messages": async () => import("./../locales/ro/server-messages.json"),
  },
  "sk": {
    "web-ng": async () => import("./../locales/sk/web-ng.json"),
    "server-messages": async () => import("./../locales/sk/server-messages.json"),
  },
};

export type TFunction = TFunctionGeneric<ApplicationMessages>;

export const useTranslation = (ns?: string, options?: UseTranslationOptions) => useTranslationGeneric<ApplicationMessages>(ns ?? "web-ng", options);

export type WithTranslationProps = WithTranslationPropsGeneric<ApplicationMessages>;

export const withTranslation = () => withTranslationGeneric<ApplicationMessages>();

export type TransProps = TransPropsGeneric<ApplicationMessages>;

export const Trans: FC<TransProps> = makeTransComponent<ApplicationMessages>();

export {
  useServerMessageTranslation,
  useApolloErrorTranslation,
  useErrorTranslation,
  formatters,
  type i18n,
} from "@equiem/localisation";

/** WARNING: Do not use in production code! For use in Cypress tests only. */
export const standaloneTestT = standaloneTestTInternal as TFunction;

/** WARNING: Do not use in production code! For use in Cypress tests only. */
export const getCustomStandaloneTestT = (options: StandaloneTestTOptions) =>
  getCustomStandaloneTestTInternal<TFunction>(options);
